import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import * as auth from 'apps/main/store/ducks/auth'
import * as restore from 'apps/main/store/ducks/restore'
import { FormBlock } from 'common/components/ui/forms/FormBlock'
import { UniversalWaiter as Waiter } from 'common/components/UniversalWaiter'
import { Button } from 'common/components/ui/Button'
import { Form } from 'common/components/ui/forms/Form'
import { Icon } from 'common/components/ui/Icon'
import Agreement from '../Agreement'

import i18n from 'i18n/locale';


// Определяем полученный язык для указания в интерфейсе
const urlParams = new URLSearchParams(window.location.search);
const language = urlParams.get('language');

if (language) i18n.changeLanguage(language);

class Finish extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    verify: PropTypes.func.isRequired,
    finish: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,

    resetTokenValidating: PropTypes.bool,
    resetTokenValid: PropTypes.bool,

    finishing: PropTypes.bool.isRequired,
    user: PropTypes.object,
  };

  static defaultProps = {
    resetTokenValidating: null,
    resetTokenValid: null,
    user: null,
  };

  state = {
    password: '',
    repeat: '',
    match: null,
  };

  onChangePassword = ({ target: { value: password } }) => {
    let match = this.state.match;

    if (match !== null) {
      match = password === this.state.repeat;
    }

    this.setState({ password, match });
  }

  onChangeRepeat = ({ target: { value: repeat } }) => {
    let match = this.state.match;

    if (match !== null) {
      match = repeat === this.state.password;
    }

    this.setState({ repeat, match });
  }

  onFinish = () => {
    const { password, repeat } = this.state;

    if (password !== repeat) {
      this.setState({ match: false });
      return;
    }

    this.props.finish(
      this.props.token,
      password,
      repeat
    );
  }

  componentDidMount() {
    if (this.props.resetTokenValidating === null) {
      this.props.verify(this.props.token);
    }
  }

  renderInvalid() {
    return (
      <div className="authblock">
        <div className="row">
          <div className="col">
            <div>
              <Icon icon="cancel" x3/>
            </div>

            <h2>{i18n.t('Восстановление доступа')}</h2>

            <p className="largetext">
              {i18n.t('Ссылка недействительна')}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <p>
              {i18n.t('Попробуйте')} <Link to="/restore" className="dottedlink">{i18n.t('повторить запрос')}</Link>
            </p>
          </div>
        </div>
      </div>
    );
  }

  renderSuccess() {
    return (
      <div className="authblock">
        <div className="row">
          <div className="col">
            <div>
              <Icon icon="success" x3 className="clr_success"/>
            </div>

            <h2>{i18n.t('Пароль сохранен')}</h2>

            <p className="largetext">
              {i18n.t('Войдите используя указанные данные для доступа')}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <FormBlock>
              <Button
                big
                maxWidth
                blue
                onClick={() => this.props.login(this.props.user)}
              >
                {i18n.t('Войти в систему')}
              </Button>
            </FormBlock>
          </div>
        </div>
      </div>
    );
  }

  renderFirst = () => {
    return (<div className="authblock" id="auth_login">
      <div className="authblock_header">
        <div className="authblock_icon">
          <img 
            src={ 
              env.ULEARN_STATIC_URL + "/branding/" + env.ULEARN_WHITELABEL + "/logo.png"
              } role="presentation"
            alt={env.ULEARN_WHITELABEL}
            style={{ height: '5rem' }}
          />
        </div>
        <h1>{i18n.t('Добро пожаловать')}</h1>
        <p className="hugetext">{i18n.t('Для активации учетной записи укажите пароль')}</p>
      </div>
      <div className="authblock_form">
        {
          this.state.match === false
            ? (
              <div className="form_message form_message_error">
                {i18n.t('Пароли не совпадают')}
              </div>
            )
            : null
        }
        <Form onSubmit={this.onFinish}>
          <FormBlock>
            <input
              type="password"
              className={`text form_el_big tac ${this.state.match === false ? 'error' : ''}`}
              value={this.state.password}
              onChange={this.onChangePassword}
              placeholder={i18n.t('Пароль')}
              autoComplete="new-password"
            />
          </FormBlock>

          <FormBlock>
            <input
              type="password"
              className={`text form_el_big tac ${this.state.match === false ? 'error' : ''}`}
              value={this.state.repeat}
              onChange={this.onChangeRepeat}
              placeholder={i18n.t('Повторите пароль')}
              autoComplete="new-password"
            />
          </FormBlock>

          <FormBlock submit>
            <Agreement />
            <Button type="submit" blue big maxWidth>
              {i18n.t('Активировать')}
            </Button>
          </FormBlock>
        </Form>
      </div>
    </div>);
  }

  render() {
    if (this.props.resetTokenValidating !== false) {
      return <Waiter logo/>;
    }

    if (!this.props.resetTokenValid) {
      return this.renderInvalid();
    }

    if (!this.props.finishing && this.props.user !== null) {
      return this.renderSuccess();
    }

    if(this.props.activation) {
      return this.renderFirst();
    }

    return (
      <div className="authblock">
        <div className="authblock_header">
            <h1>
              {i18n.t('Сменить пароль')}
            </h1>

            <p className="hugetext">
            {i18n.t('Установите новый пароль для пользователя если необходимо')}
            </p>
        </div>

        <div className="authblock_form">
            {
              this.state.match === false
                ? (
                  <div className="form_message form_message_error">
                    {i18n.t('Пароли не совпадают')}
                  </div>
                )
                : null
            }

            <Form onSubmit={this.onFinish}>
              <FormBlock>
                <input
                  type="password"
                  className="text form_el_big tac"
                  value={this.state.password}
                  onChange={this.onChangePassword}
                  placeholder={i18n.t('Пароль')}
                  autoComplete="new-password"
                />
              </FormBlock>

              <FormBlock>
                <input
                  type="password"
                  className={`text form_el_big tac ${this.state.match === false ? 'error' : ''}`}
                  value={this.state.repeat}
                  onChange={this.onChangeRepeat}
                  placeholder={i18n.t('Повторите пароль')}
                  autoComplete="new-password"
                />
              </FormBlock>
              <FormBlock submit>
                <Agreement />
                <Button type="submit" blue big maxWidth>
                  {i18n.t('Сохранить')}
                </Button>
              </FormBlock>
            </Form>
        </div>
      </div>
    );
  }
}

export default connect(
  (state, props) => ({
    activation: props.params.activation,
    token: props.params.token,
    ...state.restore
  }),
  dispatch => ({
    verify: token => dispatch(restore.verify(token)),
    finish: (token, password, repeat) => dispatch(restore.finish(token, password, repeat)),
    login: user => dispatch(auth.setAuthenticated(user)),
  })
)(Finish);
