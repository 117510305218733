import thunk from 'redux-thunk'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import { browserHistory } from 'react-router'
import { syncHistoryWithStore, routerReducer, routerMiddleware } from 'react-router-redux'
import reducersCollection, { attachServer } from './ducks'
import withReduxDevTools from 'common/stores/withReduxDevTools';

/**
 * @returns {{ store: Store, history: History }}
 */
export default function makeStore() {
  const middleware = withReduxDevTools(
    applyMiddleware(thunk, routerMiddleware(browserHistory))
  );
  const reducer = combineReducers({
    ...reducersCollection,
    routing: routerReducer
  });

  const store = applyServerCommunication(
    createStore(reducer, middleware)
  );
  const history = syncHistoryWithStore(browserHistory, store);

  return {
    store,
    history
  };
}

/**
 * @param {Store} store
 * @returns {Store}
 */
function applyServerCommunication(store) {
  attachServer(store);

  return store;
}
