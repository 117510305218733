import React from 'react'
import PropTypes from'prop-types'
import className from 'classnames'

export function Content(props) {
  
  const ContentWrapperClasses = className({
    interface_content_wrapper: true,
    narrow: props.isNarrow,
    center: props.isCenter
  });

  if (props.isWrapper) {
    return (
      <div className="interface_content">
        <div className={ContentWrapperClasses}>
          {props.children}
        </div>
      </div>
    )
  }

  return (
    <div className="interface_content">
      {props.children}
    </div>
  );
}

Content.propTypes = {
  children: PropTypes.node,
  isWrapper: PropTypes.bool,
  isNarrow: PropTypes.bool,
  isCenter: PropTypes.bool
};
