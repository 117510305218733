import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { InterfacePage } from 'common/components/ui/structure/InterfacePage'
import { Content } from 'common/components/ui/structure/Content'
import BrowserDetect from 'common/shared/BrowserDetect'
import { PlatformVerion } from 'common/components/ui/PlanformVersion'

export default class MainContainer extends Component {
  static propTypes = {
    children: PropTypes.node
  }

  render() {

    let customStyle

    if (env.ULEARN_CUSTOM_AUTHBG) {
      customStyle = {
        backgroundImage: `url(https://dd.unicraft.org/static/branding/${env.ULEARN_WHITELABEL}/authbg.jpg)`,
        backgroundPosition: "center"
      }
    }

    return (
      <InterfacePage className="interface_page_auth" style={customStyle}>
        <BrowserDetect />
        <Content>
          {this.props.children}
          <PlatformVerion withMadeBy />
        </Content>
      </InterfacePage>
    );
  }
}
