import React from 'react'
import PropTypes from'prop-types'
import makeClassName from 'classnames'

export function Icon(props) {
  const { icon, className = '', inlineStyle = {} } = props;

  // Если иконка не указана — ничего не выводим
  if (!icon) return ''

  // Класс notranslate используется для предотвращения поодмены символа переводчиками

  const iconClassName = makeClassName({
    notranslate: true,
    [`icn icn-${(icon || '').replace('svgicon_', '')}`]: true,
    icn2x: props.x2,
    icn3x: props.x3
  }) + ' ' + className;

  return (
    <i
      className={iconClassName}
      style={inlineStyle}
      aria-hidden="true"
      role="presentation"
    />
  );
}

Icon.propTypes = {
  className: PropTypes.string,

  x2: PropTypes.bool,
  x3: PropTypes.bool,

  icon: PropTypes.string.isRequired
};

Icon.defaultProps = {
  x2: false,
  x3: false,
};
