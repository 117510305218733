import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'common/components/ui/Modal'
import i18n from 'i18n/locale';
import getBrandName from 'common/lib/getBrandName';

export default class Agreement extends Component {
  static propTypes = {
    children: PropTypes.node
  }

  constructor(props) {
    super(props);

    this.state = {
      isAgreementVisible: false,
      isCookiesVisible: false,
    };
  }

  toggleAgreement = () => {
    this.setState({ isAgreementVisible: !this.state.isAgreementVisible })
  }
 
  toggleCookies = () => {
    this.setState({ isCookiesVisible: !this.state.isCookiesVisible })
  }

  render() {
    
    if (!env.ULEARN_USER_AGREEMENT) {
      return null
    }

    return (
      <Fragment>
        <div style={ { paddingBottom: "2rem", whiteSpace: "nowrap" } }>
          <p className="smalltext">{ i18n.t(`Нажимая кнопку Войти вы даете`, { button: this.props.agreeButtonTitle || i18n.t('Войти')}) }<br /> <button type="button" className="a_link" onClick={ this.toggleAgreement }>{ i18n.t('Обработку персональных данных') }</button><br /> <button type="button" className="a_link" onClick={ this.toggleCookies }>{ i18n.t('Условия использования файлов cookies') }</button></p>
        </div>

        {this.props.children }

        <Modal
          show={this.state.isAgreementVisible}
          onHide={this.toggleAgreement}
          isWide
          isPaperStyle
        >
          <div className="dialogview_content">
            <div className="typo">
              <h1>{i18n.t("personalDataConsentTitle")}</h1>
              <div dangerouslySetInnerHTML={{__html: env.ULEARN_USER_AGREEMENT_TEXT_PERSONAL || i18n.t('personalDataConsent')}} />
            </div>
          </div>
        </Modal>
        
        <Modal
          show={this.state.isCookiesVisible}
          onHide={this.toggleCookies}
          isWide
          isPaperStyle
        >
          <div className="dialogview_content">
            <div className="typo">
              <h1>{i18n.t("cookiesAgreementTitle")}</h1>
              <div dangerouslySetInnerHTML={{__html: env.ULEARN_USER_AGREEMENT_TEXT_COOKIES || i18n.t('cookiesAgreement', {brand: getBrandName(), company: env.ULEARN_USER_AGREEMENT_COMPANY || i18n.t('ООО Юникрафт')})}} />
            </div>
          </div>
        </Modal>

      </Fragment>
    );
  }
}
