import React, { Component } from 'react'
import PropTypes from 'prop-types'
import className from 'classnames'
import { Icon } from 'common/components/ui/Icon'
import { PlatformVerion } from './ui/PlanformVersion'

export function UniversalWaiterTitle(props) {
  return (
    <h1 className="waitblock_title">{props.children}</h1>
  )
};

export function UniversalWaiterInfo(props) {
  return (
    <p className="waitblock_info">{props.children}</p>
  )
};

export class UniversalWaiter extends Component {
  static propTypes = {
    logo: PropTypes.bool,
    withVersion: PropTypes.bool,
    children: PropTypes.node
  };

  static defaultProps = {
    logo: false,
    children: null
  };

  state = {
    show: false
  };

  timer = null;

  componentWillMount() {
    this.timer = setTimeout(
      () => {
        this.setState({ show: true });
        this.timer = null;
      },
      250
    );
  }

  componentWillUnmount() {
    if (this.timer !== null) {
      clearTimeout(this.timer);
    }
  }

  render() {

    const {title, info, logo, icon, withVersion} = this.props;

    const pageClassName = className({
      waitblock: true,
      waitblock_page: true,
      display_none: !this.state.show,
    });

    const loaderClassName = className({
      waitblock_loader: this.props.isCompact,
      waitblock_loader_circles: !this.props.isCompact,
    });

    return (
      <div className={pageClassName}>
        {icon
          ? <div className="waitblock_icon">
              <Icon icon='hourglass' iconFont />
            </div>
          : logo
            ? <div className="waitblock_img">
                <img 
                  src={ 
                    env.ULEARN_STATIC_URL + "/branding/" + env.ULEARN_WHITELABEL + "/logo.png"
                    } role="presentation"
                  alt="waitblock_img"
                />
              </div>
            : <div className={loaderClassName}/>
        }
        {title && (<UniversalWaiterTitle>{title}</UniversalWaiterTitle>)}
        {info && (<UniversalWaiterInfo>{info}</UniversalWaiterInfo>)}
        {this.props.children}
        {withVersion && <PlatformVerion />}
      </div>
    );
  }
}
