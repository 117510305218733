import React from 'react'
import PropTypes from 'prop-types'
import className from 'classnames'
import { Link } from 'react-router'

export const kinds = {
  button: 'button',
  link: 'link',
  a: 'a'
};

export function Button(props) {
  const {
    kind,
    hidden,
    disabled,
    blue,
    yellow,
    transparent,
    green,
    red,
    big,
    small,
    maxWidth,
    children,
    action,
    action_wait,
    action_circle,
    action_fail,
    action_success,
    button_icon_only,
    colorOnHover,
    textColorOnHover,
    ...rest
  } = props;

  const buttonProps = {
    ...rest,
    className: className({
      display_none: hidden,
      button: true,
      'disabled noevents': disabled,
      maxwidth: maxWidth,
      form_el_big: big,
      form_el_small: small,
      button_accent: blue,
      button_warning: yellow,
      button_danger: red,
      button_success: green,
      button_transparent: transparent,
      button_action: action || action_wait || action_circle || action_fail || action_success || action_circle,
      button_action_wait: action_wait,
      button_action_circle: action_circle,
      button_action_fail: action_fail,
      button_action_success: action_success,
      button_icon_only: button_icon_only,
      button_colorOnHover: colorOnHover,
      button_textColorOnHover: textColorOnHover,
    }, props.className)
  };

  if (kind === kinds.link) {
    return <Link {...buttonProps}>{children}</Link>;
  } else if (kind === kinds.a) {
    return <a {...buttonProps} href={buttonProps.to} rel="noreferrer" target="_blank">{children}</a>;
  } else {
    return <button type="button" {...buttonProps}>{children}</button>;
  }
}

Button.propTypes = {
  kind: PropTypes.oneOf(Object.keys(kinds)),
  hidden: PropTypes.bool,

  blue: PropTypes.bool,
  yellow: PropTypes.bool,
  red: PropTypes.bool,
  green: PropTypes.bool,

  big: PropTypes.bool,
  small: PropTypes.bool,
  maxWidth: PropTypes.bool,

  action: PropTypes.bool,
  action_wait: PropTypes.bool,
  action_fail: PropTypes.bool,
  action_success: PropTypes.bool,

  to: PropTypes.string,
  children: PropTypes.node
};

Button.defaultProps = {
  kind: kinds.button,
  hidden: false,

  blue: false,
  yellow: false,
  red: false,
  green: false,

  big: false,
  small: false,
  maxWidth: false,

  children: null
};

export function LinkButton(props) {
  return <Button kind={kinds.link} {...props}/>;
}

/* eslint-disable */
// Using propTypes from another component is not safe because they may be removed in production builds
LinkButton.propTypes = Button.propTypes;
if (LinkButton.propTypes && LinkButton.propTypes.kind) {
  delete LinkButton.propTypes.kind;
}
/* eslint-enable */
