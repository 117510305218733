import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import moment from 'moment';

import { resources, fallbackLng } from "./resources";

const locales = {
  ru: 'ru-ru',
  zh: 'zh-cn',
  en: 'en-us',
  fr: 'fr-fr',
  uz: 'uz-uz',
  uk: 'uk-ua',
  de: 'de-DE',
  es: 'es-es',
  pt: 'pt-pt',
  kk: 'kk-kz',
  kkcyrl: 'kk-cyrl',
  // 
  ru_einstein: 'ru-ru',
  ru_cubeonline: 'ru-ru',
  ru_cpksmo: 'ru-ru',
  ru_fmkspb: 'ru-ru',
}

moment.locale(locales[localStorage.getItem('user_lang') || env.ULEARN_LANG]);

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('user_lang') || env.ULEARN_LANG,
    fallbackLng,
  });

export default i18n;

export { moment };
