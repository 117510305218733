import * as translationRU from './base/ru.json';
import * as translationZH from './base/zh.json';
import * as translationEN from './base/en.json';
import * as translationFR from './base/fr.json';
import * as translationUZ from './base/uz.json';
import * as translationUK from './base/uk.json';
import * as translationDE from './base/de.json';
import * as translationES from './base/es.json';
import * as translationPT from './base/pt.json';
import * as translationKK from './base/kk.json';
import * as translationKKCYRL from './base/kk-cyrl.json';
 
// Индивидиуальные языки для клиентов
import * as translationRU_cubeonline from './custom/ru_cubeonline.json';
import * as translationRU_cpksmo from './custom/ru_cpksmo.json';
import * as translationRU_fmkspb from './custom/ru_fmkspb.json';

export const resources = {
  ru: {
    translation: translationRU,
  },
  zh: {
    translation: translationZH,
  },
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  uz: {
    translation: translationUZ,
  },
  uk: {
    translation: translationUK,
  },
  de: {
    translation: translationDE,
  },
  es: {
    translation: translationES,
  },
  pt: {
    translation: translationPT,
  },
  kk: {
    translation: translationKK,
  },
  kkcyrl: {
    translation:  translationKKCYRL,
  },
  ru_cubeonline: {
    translation: translationRU_cubeonline,
  },
  ru_cpksmo: {
    translation: translationRU_cpksmo,
  },
  ru_fmkspb: {
    translation: translationRU_fmkspb,
  },
};

export const fallbackLng = {
  'ru_einstein': ['ru'],
  'ru_cubeonline': ['ru'],
  'ru_cpksmo': ['ru'],
  'ru_fmkspb': ['ru'],
  'uz': ['ru'],
  'uk': ['ru'],
  'kk': ['kk-cyrl', 'ru'],
  'kk-cyrl': ['ru'],
  'default': ['en', 'ru']
}
