import React, { Component } from 'react';
import EventEmitter from 'wolfy87-eventemitter';

const ee = new EventEmitter();
const events = {
  push: 'push'
};

export type Action = {
  title: string,
  fn(): void
}

export type Notification = {
  type:  'success' | 'warning' | 'danger',
  title: string,
  actions?: Action[],
  timeout?: number,
  closeable?: boolean
}

type Id = {
  id: string
}

const notificationTypeClass = {
  success: 'global_notificator_item_success',
  warning: 'global_notificator_item_warning',
  danger: 'global_notificator_item_danger'
};

export class GlobalNotifier extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications: new Map()
    };

    this.onPush = (n: Id & Notification) => {
      if (n.timeout !== 0) {
        n.timeout = setTimeout(() => {
          this.removeNotification(n.id);
        }, n.timeout);
      }

      this.addNotification(n);
    };
  }

  componentWillMount() {
    ee.on(events.push, this.onPush);
  }

  componentWillUnmount() {
    ee.removeListener(events.push, this.onPush);
  }

  removeNotification(id: string) {
    const notifications = new Map(this.state.notifications);

    notifications.delete(id);

    this.setState({ notifications });
  }

  addNotification(n: Id & Notification) {
    const notifications = new Map(this.state.notifications);

    notifications.set(n.id, n);

    this.setState({ notifications });
  }

  renderNotification(n: Id & Notification) {
    let actions: Action[] = null;
    let close = null;

    if (n.actions.length !== 0) {
      actions = n.actions.map((action: Action, index: number) => (
        <button key={index} className="dottedlink" onClick={action.fn}>
          {action.title}
        </button>
      ));
    }

    if (n.closeable) {
      close = <button type="button" className="close" onClick={() => this.removeNotification(n.id)}/>;
    }

    return (
      <div key={n.id} className={`global_notificator_item ${notificationTypeClass[n.type]}`}>
        {n.title}
        {actions}
        {close}
      </div>
    );
  }

  render() {
    const notifications = [];

    for (const n of this.state.notifications.values()) {
      notifications.push(this.renderNotification(n));
    }

    return (
      <div
        className="global_notificator"
        style={{ position: 'fixed' }}
      >
        {notifications}
      </div>
    );
  }
}

export function showNotification({
  type,
  title,
  actions = [],
  timeout = 3000,
  closeable = false
}: Notification) {
  ee.emit(events.push, {
    id: Math.random().toString(16),
    type,
    title,
    actions,
    timeout,
    closeable
  });
}

export function showSuccessNotification({
  title,
  type = 'success',
  actions = [],
  timeout = 3000,
  closeable = true
}: Notification) {
  showNotification({ type, title, actions, timeout, closeable });
}

export function showWarningNotification({
  title,
  type = 'warning',
  actions = [],
  timeout = 3000,
  closeable = true
}: Notification) {
  showNotification({ type, title, actions, timeout, closeable });
}

export function showDangerNotification({
  title,
  type = 'danger',
  actions = [],
  timeout = 3000,
  closeable = false
}: Notification) {
  showNotification({ type, title, actions, timeout, closeable });
}

export function showErrorNotification({
  title,
  type = 'danger',
  actions = [],
  timeout = 3000,
  closeable = false
}: Notification) {
  showNotification({ type, title, actions, timeout, closeable });
}
