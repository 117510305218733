import React, { Component } from 'react'
import i18n from 'i18n/locale';

import badgeHuaweiappgalleryRu from 'design/style/img/appbadges/badge-huaweiappgallery-ru.svg'
import badgeHuaweiappgalleryEn from 'design/style/img/appbadges/badge-huaweiappgallery-en.svg'
import badgeAppstoreRu from 'design/style/img/appbadges/badge-appstore-ru.svg'
import badgeAppstoreEn from 'design/style/img/appbadges/badge-appstore-en.svg'
import badgeGoogleplayRu from 'design/style/img/appbadges/badge-googleplay-ru.svg'
import badgeGoogleplayEn from 'design/style/img/appbadges/badge-googleplay-en.svg'

export default class AppsLinks extends Component {
  render() {

    let appBadges

    if (!env.ULEARN_MOBILEAPP_LINKS) {
      return null
    }

    if (env.ULEARN_LANG === 'ru') {
      appBadges = {
        apple: badgeAppstoreRu,
        google: badgeGoogleplayRu,
        huawei: badgeHuaweiappgalleryRu
      };
    } else {
      appBadges = {
        apple: badgeAppstoreEn,
        google: badgeGoogleplayEn,
        huawei: badgeHuaweiappgalleryEn
      };
    }

    return (
      <>
        <div className="apps_links">
          <a href="https://apps.apple.com/ru/app/unicraft/id1540267679" target="_blank" rel="noreferrer">
            <img loading="lazy" src={ appBadges.apple } alt={ i18n.t("Загрузите в App Store") } />
          </a>
          <a href="https://play.google.com/store/apps/details?id=org.unicraft.app" target="_blank" rel="noreferrer">
            <img loading="lazy" src={ appBadges.google } alt={ i18n.t("Загрузите в Google Play") } />
          </a>
          <a href="https://appgallery.huawei.com/app/C103938597" target="_blank" rel="noreferrer">
            <img loading="lazy" src={ appBadges.huawei } alt={ i18n.t("Загрузите в HUAWEI AppGallery") } />
          </a>
        </div>
      </>
    )
  }
}
