import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { Icon } from 'common/components/ui/Icon'
import { host } from 'common/server'

import i18n from 'i18n/locale';

export function RoleSelector(props, context) {

    const { user, urlParams } = props;

    if (!user) {
        return null
    }

    const appId = context.appId;

    const root = 'root';
    const admin = 'admin';
    const manager = 'manager';
    const teacher = 'teacher';
    const student = 'student';

    const rolesAccess = {
        [root]: [root, admin, manager, teacher, student],
        [admin]: [admin, manager, teacher, student],
        [manager]: env.ULEARN_WHITELABEL !== 'einstein' ? [manager, teacher, student] : [manager, student],
        [teacher]: [teacher, student],
        [student]: [student]
    };

    const rolesText = {
        [root]: i18n.t('Суперпользователь'),
        [admin]: i18n.t('Администратор'),
        [manager]: i18n.t('Менеджер'),
        [teacher]: i18n.t('Тренер'),
        [student]: i18n.t('Студент'),
    };

    const rolesLink = {
        [root]: root,
        [admin]: admin,
        [manager]: manager,
        [teacher]: 'trainer',
        [student]: student
    };

    const rolesIcons = {
        [root]: 'role-root',
        [admin]: 'role-admin',
        [manager]: 'role-manager',
        [teacher]: 'role-trainer',
        [student]: 'role-student'
    };

    const rolesAvailable = [];

    for (const role of rolesAccess[user.access]) {
        const roleActive = role.replace('teacher', 'trainer') === appId;

        rolesAvailable.push(
            <li
                key={role}
                className={roleActive ? 'active' : ''}
            >
                <Link
                    className="item_link"
                    href={`${host}/${rolesLink[role]}${urlParams ? `?${urlParams}` : ""}`}
                />
                <span className="icolink nowrap">
                    <Icon icon={rolesIcons[role]} />
                    {rolesText[role]}
                </span>
            </li>
        );
    }

    return (
        <ul className="linelist navlist">
            {rolesAvailable}
        </ul>
    );
}

RoleSelector.propTypes = {
    user: PropTypes.object.isRequired,
};

RoleSelector.contextTypes = {
    router: PropTypes.object,
    appId: PropTypes.string,
};
