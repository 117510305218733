import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from 'react-router'
import { connect } from "react-redux";

import { UniversalWaiter as Waiter } from "common/components/UniversalWaiter";
import { host } from "common/server";
import i18n from "i18n/locale";
import { Icon } from "common/components/ui/Icon";
import { Button } from "common/components/ui/Button";
import { FormBlock } from "common/components/ui/forms/FormBlock";
import { Form } from "common/components/ui/forms/Form";
import * as registrationStore from "apps/main/store/ducks/registration";
import { GlobalNotifier } from "common/components/GlobalNotifier";
import AppsLinks from './AppsLinks'

import Agreement from "./Agreement";

// Определяем полученный язык для указания в интерфейсе
const urlParams = new URLSearchParams(window.location.search);
const language = urlParams.get("language");

if (language) i18n.changeLanguage(language);

class Registration extends Component {
  static propTypes = {
    departmentId: PropTypes.string,
    isRegistrationSuccess: PropTypes.bool,
    registeredUser: PropTypes.object,
    departmentTitle: PropTypes.string,
    hasError: PropTypes.bool,

    registrate: PropTypes.func.isRequired,
    validateDepartmentIdOnLoad: PropTypes.func.isRequired,
  };

  static defaultProps = {
    departmentId: "",
  };

  state = {
    isFormValid: false,
    errors: {
      emptyName: false,
      emptyEmail: false,
    },
    user: {
      name: "",
      email: "",
    },
  };

  componentDidMount = () => {
    this.props.validateDepartmentIdOnLoad(this.props.params.departmentId);
  };

  makeOnChange = ({ target: { name, value } }) => {
    const newState = {
      user: {
        ...this.state.user,
        [name]: value,
      },
      errors: {},
    };
    this.setState(newState);
  };

  onSave = () => {
    const errors = {};
    let hasError = false;

    if (this.state.user.name.length === 0) {
      errors.emptyName = true;
      hasError = true;
    }

    if (this.state.user.email.length === 0) {
      errors.emptyEmail = true;
      hasError = true;
    }

    if (hasError) {
      this.setState({
        ...this.state,
        errors,
      });
    } else {
      this.props.registrate(this.props.departmentId, this.state.user);
    }
  };

  renderCommonError(params) {
    const message =
      (params && params.errorMessage) ||
      i18n.t("Обратитесь к администратору");
    const passwordRestoreSuggestion =
      (params && params.passwordRestoreSuggestion) || false;

    return (
      <div className="authblock">
        <div className="authblock_header">
          <div className="authblock_icon">
            <img
              src={
                env.ULEARN_STATIC_URL +
                "/branding/" +
                (env.ULEARN_WHITELABEL || "unicraft") +
                "/logo.png"
              }
              alt="logo"
            />
          </div>
          <h1>{i18n.t("Регистрация недоступна")}</h1>
          <p className="hugetext">{message}</p>
        </div>
        {passwordRestoreSuggestion && (
          <div className="authblock_footer">
            <p dangerouslySetInnerHTML={{__html: i18n.t("При отсутствии письма с подтверждением можно восстановить пароль", {link: '/restore'})}}/>
          </div>
        )}
      </div>
    );
  }

  renderSuccess(groupTitle, isNewUser) {
    return (
      <div className="authblock">
        <div className="authblock_header">
          <div className="authblock_icon">
              <Icon icon="success" x3 className="clr_success" />
          </div>
          <h1>{i18n.t("Регистрация прошла успешно")}</h1>
          <p className="hugetext">
            {i18n.t(`Вы добавлены в группу`, {title: groupTitle})}
          </p>
        </div>

        <div className="authblock_form">
          {isNewUser ? (
            <p className="largetext">
              {i18n.t(
                "На указанный email отправлено письмо с ссылкой для активации нового пользователя"
              )}
            </p>
          ) : (
            <FormBlock>
              <Button
                big
                maxWidth
                blue
                onClick={() => window.location.replace(`//${host}/student`)}
              >
                {i18n.t("Войти в систему")}
              </Button>
            </FormBlock>
          )}
        </div>
        <div className="authblock_footer">
          <AppsLinks />
        </div>
      </div>
    );
  }

  renderMainForm() {
    return (
      <div className="authblock" id="auth_login">
        <GlobalNotifier />
        <div className="authblock_header">
          <div className="authblock_icon">
            <img
              src={
                env.ULEARN_STATIC_URL + 
                "/branding/" +
                (env.ULEARN_WHITELABEL || "unicraft") +
                "/logo.png"
              }
              alt=""
            />
          </div>
          <h1>{i18n.t("Регистрация")}</h1>
          <p className="hugetext">
            {i18n.t("Для регистрации учетной записи заполните все поля формы")}
          </p>
        </div>
        <div className="authblock_form">
          <Form onSubmit={this.onSave}>
            {this.state.errors.emptyName && (
              <FormBlock>
                <div className="form_message form_message_error">
                  {i18n.t("Имя не может быть пустым")}
                </div>
              </FormBlock>
            )}
            <FormBlock>
              <input
                className="text form_el_big"
                type="text"
                placeholder={i18n.t("ФИО")}
                minLength="6"
                maxLength="320"
                name="name"
                value={this.state.user.name}
                onChange={this.makeOnChange}
              />
            </FormBlock>
            {this.state.errors.emptyEmail && (
              <FormBlock>
                <div className="form_message form_message_error">
                  {i18n.t("Email необходим для авторизации")}
                </div>
              </FormBlock>
            )}
            <FormBlock>
              <input
                className="text form_el_big"
                type="email"
                placeholder={i18n.t("Email")}
                maxLength="320"
                name="email"
                value={this.state.user.email}
                onChange={this.makeOnChange}
              />
            </FormBlock>

            <FormBlock submit>
              <Agreement agreeButtonTitle={i18n.t("Зарегистрироваться")} />
              <Button
                big
                maxWidth
                blue
                type="submit"
                action_wait={this.props.initializing}
                disabled={this.props.initializing}
              >
                {i18n.t("Зарегистрироваться")}
              </Button>
              <p style={{marginTop: "2rem"}}>
                  <Link className="authblock_restore_link" href="/">{i18n.t('Уже зарегистрированы Авторизуйтесь')}</Link>
              </p>
            </FormBlock>
            
          </Form>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.initializing) {
      return <Waiter />;
    }

    if (!this.props.departmentId) {
      return this.renderCommonError();
    }

    if (this.props.hasError) {
      const { errorMessage, passwordRestoreSuggestion } = this.props;
      return this.renderCommonError({
        errorMessage,
        passwordRestoreSuggestion,
      });
    }

    if (this.props.isRegistrationSuccess) {
      const { departmentTitle, registeredUser } = this.props;
      return this.renderSuccess(departmentTitle, registeredUser.isNewUser);
    }

    return this.renderMainForm();
  }
}

export default connect(
  (state, props) => {
    return {
      departmentId: props.params.departmentId,
      ...state.registration,
    };
  },
  (dispatch) => ({
    registrate: (departmentId, user) =>
      dispatch(registrationStore.registrate(departmentId, user)),
    validateDepartmentIdOnLoad: (departmentId) =>
      dispatch(registrationStore.validateDepartmentIdOnLoad(departmentId)),
  })
)(Registration);
