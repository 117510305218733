import React from 'react'
import PropTypes from'prop-types'

export function Form({ children, onSubmit, ...props }) {
  return (
    <form
      {...props}
      onSubmit={event => { event.preventDefault(); onSubmit(); }}
    >
      {children}
    </form>
  );
}

Form.propTypes = {
  children: PropTypes.node,
  onSubmit: PropTypes.func
};
