import qs from 'query-string';
import {
  host,
  onConnect,
  onReconnect,
} from 'common/server'
import {
  acquireToken,
  authenticate
} from 'common/server/auth'
import isMobile from 'common/lib/isMobile';

const queryString = qs.parse(window.location.search);

if (queryString.sso) {
  window.localStorage['token'] = queryString.sso;
}

const initialToken = window.localStorage['token'] || null;

const initialState = {
  authenticated: false,
  authenticating: !!initialToken,
  error: null,
  token: initialToken,
  // используется для отмены автоматического перенаправления на домашнюю страницу авторизованного пользователя,
  // если таковой уже аутентифицировался, например, в другой вкладке браузера. По умолчанию, редирект происходит
  preventRedirection: false
};

const SET_TOKEN = 'uc/lms/main/auth/setToken';
function doSetToken(state, { token }) {
  window.localStorage['token'] = token;

  return {
    ...state,
    token
  }
}

export const SET_AUTHENTICATED = 'uc/lms/main/auth/setAuthenticated';
function doSetAuthenticated(state, { user, preventRedirection = false }) {
  if (preventRedirection) {
    return {
      ...state,
      preventRedirection
    };
  }

  const role = user.access.replace('teacher', 'trainer');

  const urlParams = new URLSearchParams(window.location.search);
  const redirectTo = urlParams.get("redirectTo");

  if (redirectTo) {
    window.location.replace(redirectTo);
  } else if (!state.preventRedirection && (!isMobile() || (role === 'student'))) {
    window.location.replace(`//${host}/${role}`);
  }

  return {
    ...state,
    user,
    authenticated: true,
  }
}

const SET_ERROR = 'uc/lms/main/auth/setError';
function doSetError(state, { error }) {
  return {
    ...state,
    authenticated: false,
    authenticating: false,
    token: null,
    error
  }
}

const SET_UNAUTHENTICATED = 'uc/lms/main/auth/setUnauthenticated';
function doSetUnauthenticated(state, { error = null }) {
  delete window.localStorage['token'];

  return {
    ...state,
    authenticated: false,
    authenticating: false,
    token: null,
    error
  }
}

const SET_AUTHENTICATING = 'uc/lms/main/auth/setAuthenticating';
function doSetAuthenticating(state) {
  return {
    ...state,
    authenticating: true,
    errors: null
  }
}

export default function authenticator(state = initialState, action) {
  switch (action.type) {
    case SET_TOKEN: return doSetToken(state, action);
    case SET_ERROR: return doSetError(state, action);
    case SET_AUTHENTICATED: return doSetAuthenticated(state, action);
    case SET_AUTHENTICATING: return doSetAuthenticating(state);
    case SET_UNAUTHENTICATED: return doSetUnauthenticated(state, action);

    default: return state;
  }
}

/**
 * @api
 * @param email
 * @param password
 * @returns {function(*)}
 */
export function login(email, password) {
  return async dispatch => {
    dispatch(setAuthenticating());

    const token = await acquireToken(email, password).catch(error => {
      dispatch({ type: SET_ERROR, error });

      return null;
    });

    if (token === null) {
      return;
    }

    dispatch(setToken(token));

    const user = await authenticateConnection(token);

    if (user === null) {
      return dispatch(setUnauthenticated());
    }

    if (user.deactivated) {
      const error = [ 102, "User deactivated" ]
      
      dispatch(setUnauthenticated())
      dispatch({ type: SET_ERROR, error });

      return null;
    }

    dispatch(setAuthenticated(user));
  }
}

/**
 * @api
 * @returns {{type}}
 */
export function logout() {
  return setUnauthenticated();
}

/**
 * @api
 * @param token
 * @returns {{type: string, token: *}}
 */
export function setToken(token) {
  return {
    type: SET_TOKEN,
    token
  }
}

/**
 * @api
 * @param user
 * @returns {{type: string, user: *}}
 */
export function setAuthenticated(user) {
  return {
    type: SET_AUTHENTICATED,
    user
  }
}

/**
 * @api
 * @returns {{type: string}}
 */
export function setAuthenticating() {
  return {
    type: SET_AUTHENTICATING
  }
}

/**
 * @api
 * @returns {{type: string}}
 */
export function setUnauthenticated() {
  return {
    type: SET_UNAUTHENTICATED
  }
}

function authenticateConnection(token) {
  return authenticate(token).catch(() => null);
}

export function attachAuthenticator({ dispatch }) {
  if (!initialToken) {
    return;
  }

  onConnect(async () => {
    const user = await authenticateConnection(initialToken);

    if (user !== null) {
      dispatch(setAuthenticated(user));
    } else {
      dispatch(setUnauthenticated());
    }
  });

  onReconnect(() => {
    window.location.reload();
  })
}
