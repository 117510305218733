import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'common/components/ui/Icon'

export function Empty(props) {
  let icon = null;
  let waiter = null;

  if (props.icon) {
    if (props.iconFont) {
      icon = (
        <div className="empty_data_icon">
          <Icon icon={props.icon}/>
        </div>
      );
    } else {
      icon = (
        <div className="empty_data_icon">
          <img src={props.icon} role="presentation" alt="empty_data_icon"/>
        </div>
      );
    }
  }
  
  if (props.withWaiter) {
    waiter = (
      <div className="empty_data_icon">
        <div className="waitblock_loader_circles"/>
      </div>
    );
  }

  const description = props.description
    ? <div className="empty_data_desc">{props.description}</div>
    : null;

  const actions = props.children
    ? <div className="empty_data_actions">{props.children}</div>
    : null;

  return (
    <div className="empty_data">
      {waiter || icon }
      <div className="empty_data_title">
        {props.title}
      </div>
      {description}
      {actions}
    </div>
  );
}

Empty.propTypes = {
  title: PropTypes.string.isRequired,

  description: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.string,
  iconFont: PropTypes.bool,
};

Empty.defaultProps = {
  description: null,
  children: null,
  icon: null,
  iconFont: false,
};
