import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { UniversalWaiter, UniversalWaiter as Waiter } from 'common/components/UniversalWaiter'
import { Form } from 'common/components/ui/forms/Form'
import { Button } from 'common/components/ui/Button'
import {
  login as loginAction,
  logout as logoutAction
} from 'apps/main/store/ducks/auth'
import { FormBlock } from 'common/components/ui/forms/FormBlock'
import Agreement from './Agreement'
import AppsLinks from './AppsLinks'

import i18n from 'i18n/locale';
import { RoleSelector } from 'common/components/ui/structure/RoleSelector'
import isMobile from 'common/lib/isMobile'
import { Helmet } from 'react-helmet';
import { Empty } from 'common/components/ui/Empty'

class AuthContainer extends Component {
  static propTypes = {
    authenticated: PropTypes.bool.isRequired,
    authenticating: PropTypes.bool.isRequired,
    token: PropTypes.string,
    error: PropTypes.array,

    login: PropTypes.func.isRequired,

    isWebMobileApp: PropTypes.bool,
    isStandalone: PropTypes.bool,
    isMobile: PropTypes.bool,
  }

  static defaultProps = {
    token: null,
    error: null,
    isWebMobileApp: false,
    isStandalone: false,
    isMobile: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      isWebMobileApp: false,
      isStandalone: false,
      isMobile: false,
    };
  }

  componentDidMount() {
    // Фиксируем что мы зашли с мобильного приложения для будущего использования
    const urlParams = new URLSearchParams(window.location.search);
    const isWebMobileApp = urlParams.get("isWebMobileApp");

    if (isWebMobileApp === 'true' && !localStorage.getItem('isWebMobileApp')) {
      localStorage.setItem('isWebMobileApp', 'true');
    }

    // Опредляем способ открытия (через вкладку браузера или отдельно) https://web.dev/learn/pwa/detection
    let displayMode = 'browser tab';
    if (window.matchMedia('(display-mode: standalone)').matches) {
      displayMode = 'standalone';
    }

    this.setState({
      isWebMobileApp: isWebMobileApp === 'true',
      isStandalone: displayMode === 'standalone',
      isMobile: isMobile(),
    });
  }
  
  onEmailChange = ({ target: { value: email } }) => {
    this.setState({ email: email?.replace(/\s+/g, '') });
  }

  onPasswordChange = ({ target: { value: password } }) => {
    this.setState({ password });
  }

  onLogin = () => {
    this.props.login(
      this.state.email.toLocaleLowerCase(),
      this.state.password
    );

    return false;
  }

  onLogout = () => {
    this.props.logout()

    return false;
  }

  render() {
    const { error, token, authenticating, authenticated } = this.props;
    const { email, password, isWebMobileApp, isStandalone } = this.state;

    if (token && authenticating && !authenticated) {
      return <Waiter withVersion logo/>;
    }

    // Смотрим, не является ли это страницей выбора роли
    const isRolePage = window.location.pathname.includes('role')
    const urlParams = new URLSearchParams(window.location.search);

    // если это мобильное приложение  - то сразу перенаправляем на студента
    if (authenticated && isWebMobileApp) {
      window.location.href = "/student"
      return <UniversalWaiter />
    }

    if (authenticated && this.props.user && this.props.user.access !== "student" && isMobile()) {

      if (isWebMobileApp && !env.ULEARN_MOBILEAPP_ACCESS) {
        return <>
          <Empty
            title={i18n.t("Доступ к платформе через Мобильное приложение закрыт")}
            description={i18n.t("Перейдите на веб версию платформы url", {url: window.location.host})}
          >
            <div className="form_bl">
              <Button
                type="button"
                onClick={() => this.props.logout()}
              >
                {i18n.t('Выйти')}
              </Button>
            </div>
          </Empty>
        </>
      }

      if (!isRolePage) {
        this.props.router.push(`/role${urlParams ? `?${urlParams}` : ""}`)
      }

      return (
        <Fragment>
          <div className="authblock">
            <div className="authblock_header">
              <div className="authblock_icon">
                <img
                  src={
                    env.ULEARN_STATIC_URL + "/branding/" + env.ULEARN_WHITELABEL + "/logo.png"
                  } role="presentation"
                  alt={env.ULEARN_WHITELABEL}
                />
              </div>
              <p className="hugetext">
                {i18n.t('Здравствуйте! Выберите роль', {userName: this.props.user.name || ""})}:
              </p>
            </div>
  
            <div className="authblock_form">
              <RoleSelector user={this.props.user} urlParams={urlParams} />
            </div>
            <div className="authblock_footer">
              <FormBlock>
                <Button
                  transparent
                  type="button"
                  onClick={this.onLogout}
                >
                  {i18n.t('Выйти')}
                </Button>
              </FormBlock>
            </div>
          </div>
        </Fragment>
      );
    }

    // Возвращаем на общую страницу 
    if (isRolePage) {
      this.props.router.push("/")
    }

    let errorMessage
    
    if (error) {
      switch(error[0]) {
        case 101:
          errorMessage = i18n.t('Неправильный логин или пароль')
          break;

        case 102:
          errorMessage = i18n.t('Пользователь деактивирован')
          break;

        default:
          errorMessage =  i18n.t('Что-то пошло не так')
          break;
      }
    }

    return (
      <Fragment>
        <div className="authblock">
          <div className="authblock_header">
            <div className="authblock_icon">
                <img
                  src={ 
                    env.ULEARN_STATIC_URL + "/branding/" + env.ULEARN_WHITELABEL + "/logo.png"
                    } role="presentation"
                  alt={env.ULEARN_WHITELABEL}
                />
            </div>
            <p className="hugetext">
              {i18n.t('Для входа в систему укажите адрес электронной почты и пароль')}
            </p>
          </div>

          <div className="authblock_form">
              <Form onSubmit={this.onLogin}>
                {
                  error
                    ? (
                      <div className="form_message form_message_error">
                        {errorMessage}
                      </div>
                    )
                    : null
                }

                <FormBlock>
                  <input
                    className="text form_el_big"
                    type="text"
                    placeholder={i18n.t('Логин')}
                    value={email}
                    onChange={this.onEmailChange}
                    onBlur={this.onEmailChange}
                    autoFocus
                    disabled={authenticating}
                  />
                </FormBlock>
                <FormBlock>
                  <input
                    className="text form_el_big"
                    type="password"
                    placeholder={i18n.t('Пароль')}
                    value={password}
                    onChange={this.onPasswordChange}
                    disabled={authenticating}
                  />
                </FormBlock>
                <FormBlock submit>
                  <Agreement />
                  <Button
                    big
                    action
                    blue
                    action_wait={authenticating}
                    disabled={authenticating}
                    type="submit"
                  >
                    {i18n.t('Войти')}
                  </Button>
                  <p style={{marginTop: "2rem"}}>
                    <Link to="/restore" className="authblock_restore_link">{i18n.t('Восстановить пароль')}</Link>
                  </p>
                </FormBlock>
              </Form>
          </div>
          <div className="authblock_footer">
            {env.ULEARN_MOBILEAPP_LINKS && <>
              {!(isWebMobileApp || isStandalone) && <Helmet>
                <meta name="google-play-app" content="app-id=org.unicraft.app" />
                <meta name="apple-itunes-app" content="app-id=1540267679" />
              </Helmet>}
              <AppsLinks />
            </>
            }
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(
  state => ({
    ...state.authenticator
  }),
  dispatch => ({
    login: (email, password) => dispatch(loginAction(email, password)),
    logout: () => dispatch(logoutAction())
  })
)(AuthContainer);
