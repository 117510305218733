import { api, subscribe } from './index'
import { Event } from "domains/events";

export function get(id) {
  return api(Event.UserGet, id);
}

export function getAll() {
  return api(Event.UsersGetAll);
}

export function getAllByRole(role) {
  return api(Event.UsersGetAll, { role });
}

export function getAllByDepartment(department) {
  return api(Event.UsersGetAll, { department });
}

export function getAllByIds(ids) {
  return api(Event.UsersGetAll, { ids });
}

export function update(user) {
  return api(Event.UserUpdate, user);
}

export function invite(user) {
  return api(Event.UserInvite, user);
}

export function inviteBulk(users) {
  return api(Event.UserInviteBulk, users);
}

export function setUserResetToken(user) {
  return api(Event.SetUserResetToken, user);
}

export function updatePassword(user_id, password) {
  return api(Event.UserUpdatePassword, { user_id, password });
}

export function updateForDepartment(department_id, user) {
  return api(Event.UserUpdateForDepartment, { department_id, user });
}

export function selfRegistrationForDepartment(department_id, user) {
  return api(Event.UserSelfRegistration, { department_id, user });
}

export function hardDelete(userId, byWhom) {
  return api(Event.UserHardDelete, {userId, byWhom});
}

export function restoreDeletedUser(userId) {
  return api(Event.UserRestoreDeleted, userId);
}

export function listen(cb) {
  subscribe(Event.UserUpdated, cb);
}

export function listenOne(id, cb) {
  subscribe(`${Event.UserUpdated}:${id}`, cb);
  subscribe(`${Event.UserDisconnected}:${id}`, cb);
}

export function selfUpdate(user) {
  return api(Event.UserSelfUpdate, user);
}

export function updateFiedls(user_id, fields) {
  return api(Event.UserUpdateFields, { user_id, fields });
}

export function activationToggle(userId, activate, byWhom) {
  return api(Event.UserActivationToggle, {userId, activate, byWhom});
}
