

function isMobile() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    const matchType = toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });

    return Boolean(matchType && window.screen.orientation)
}

export default isMobile