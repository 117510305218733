import r from 'rethinkdb';
import { ComputedUserMeetupState } from './computeUserMeetupState';

export enum DependentEntities {
  applications = 'applications',
  arrangements = 'arrangements',
  trainers = 'trainers',
  students = 'students',
}

export type EntitiesMapping = Record<DependentEntities, r.Table>;

/* Мероприятия. Такое имя выбрано (вместо Event), чтобы не путать непосредственно с Event */
export interface MeetUp {
  id: string;
  image: string;
  name: string;
  customId?: string;
  description?: string;
  min_score?: string;
  trainerQuality?: boolean;
}

export interface MeetUpDependency {
  id?: string;
  meetup_id: string;
}

export interface MeetUpArrangement extends MeetUpDependency {
  date_start: number;
  date_end: number;
  timeZoneOffsetMinutes?: number | string;
  max_participants?: number;
  comment?: string;
  customId?: string;

  updated_at?: number;
  updated_by?: string;

  report?: {
    video?: {
      type?: 'uploaded' | 'embedded',
      link?: string,
      embedLink?: string,
    },
    description?: string;
    attachments?: Record<string, {
      ext: string;
      fileId: string;
      id: string;
      name: string;
    }>;
  }

  placeType: 'online' | 'offline',
  placeSubType?: 'pruffme',
  placeInfo: string,
  pruffmeWebinarHash?: string,
  pruffmeWebinarLogin?: string,
  pruffmeWebinarName?: string,
}

export interface MeetUpTrainer extends MeetUpDependency{
  user_id: string;
  department_id?: string;
}

export enum ApplicationStatus {
  UNKNOWN = 'Unknown',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  CONFIRMED = 'confirmed',
  DECLINED = 'declined',
}

interface MeetUpUser extends MeetUpDependency {
  user_id: string;
  status: ApplicationStatus;
  arrangement_id: string;
  timestamp: number;
  individual_assignation?: boolean;
  plans_related?: string[];
  application_id?: string;
}

export interface MeetUpStudent extends MeetUpUser {
  presence?: ApplicationStatus;
  efficiency?: string;
  quality?: string;
  comment?: string;
  department_id?: string;
  blocked?: boolean;
  blocked_at?: number;
  blockedByPlan?: boolean;
  states?: ComputedUserMeetupState
  started_at?: number
  finished_at?: number
}

export interface MeetUpApplication extends MeetUpUser {
  created_at: string;
  updated_at: string;
  updated_by?: string;
}