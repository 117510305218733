import React from 'react';
import i18n from 'i18n/locale'

function detectBrowser() {

  var module = {
    options: [],
    header: [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor, window.opera],
    dataos: [
      { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
      { name: 'Windows', value: 'Win', version: 'NT' },
      { name: 'iPhone', value: 'iPhone', version: 'OS' },
      { name: 'iPad', value: 'iPad', version: 'OS' },
      { name: 'Kindle', value: 'Silk', version: 'Silk' },
      { name: 'Android', value: 'Android', version: 'Android' },
      { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
      { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
      { name: 'Macintosh', value: 'Mac', version: 'OS X' },
      { name: 'Linux', value: 'Linux', version: 'rv' },
      { name: 'Palm', value: 'Palm', version: 'PalmOS' }
    ],
    databrowser: [
      { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
      { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
      { name: 'Safari', value: 'Safari', version: 'Version' },
      { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
      { name: 'Opera', value: 'Opera', version: 'Opera' },
      { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
      { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
    ],
    init: function () {
      var agent = this.header.join(' '),
        os = this.matchItem(agent, this.dataos),
        browser = this.matchItem(agent, this.databrowser);

      return { os: os, browser: browser };
    },
    matchItem: function (string, data) {
      var i = 0,
        j = 0,
        regex,
        regexv,
        match,
        matches,
        version;

      for (i = 0; i < data.length; i += 1) {
        regex = new RegExp(data[i].value, 'i');
        match = regex.test(string);
        if (match) {
          regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
          matches = string.match(regexv);
          version = '';
          if (matches) { if (matches[1]) { matches = matches[1]; } }
          if (matches) {
            matches = matches.split(/[._]+/);
            for (j = 0; j < matches.length; j += 1) {
              if (j === 0) {
                version += matches[j] + '.';
              } else {
                version += matches[j];
              }
            }
          } else {
            version = '0';
          }
          return {
            name: data[i].name,
            version: parseFloat(version)
          };
        }
      }
      return { name: 'unknown', version: 0 };
    }
  };

  var e = module.init();

  // 
  // var debug = '';

  // debug += 'os.name = ' + e.os.name + '<br/>';
  // debug += 'os.version = ' + e.os.version + '<br/>';
  // debug += 'browser.name = ' + e.browser.name + '<br/>';
  // debug += 'browser.version = ' + e.browser.version + '<br/>';

  // debug += '<br/>';
  // debug += 'navigator.userAgent = ' + navigator.userAgent + '<br/>';
  // debug += 'navigator.appVersion = ' + navigator.appVersion + '<br/>';
  // debug += 'navigator.platform = ' + navigator.platform + '<br/>';
  // debug += 'navigator.vendor = ' + navigator.vendor + '<br/>';

  // 

  return e.browser

};

function onbrowserNotificationClose() {
  document.cookie = "browserNotificationClosed=1; Max-Age=160000; SameSite=Lax; path=/";
}

export default class BrowserDetect extends React.Component {
  render() {

    // Смотрим, не было закрыто ранее это сообщение
    const warningCookie = document.cookie.split('; ')
      .find(row => row.startsWith('browserNotificationClosed'))
    const warningWasClosed = warningCookie ? warningCookie.split('=')[1] : '';

    // Если закрыто — не проверяем, либо идем дальше
    if (warningWasClosed === '1') {
      return null
    }

    const browser = detectBrowser()
    let isSupported = true;

    if (
      (browser.name === 'Firefox' && browser.version < 74) ||
      (browser.name === 'Chrome' && browser.version < 81) ||
      (browser.name === 'Safari' && browser.version < 10) ||
      (browser.name === 'Internet Explorer' && browser.version < 11) ||
      (browser.name === 'Opera' && browser.version < 60)
    ) {
      isSupported = false
    }

    // console.log(browser.name, browser.version);
    // console.log('Is supported: ', isSupported);
    // console.log('Warning was closed: ', warningWasClosed === '1');

    // Если с версиями все ок — ничего не показываем, либо...
    if (isSupported || browser.version === 0) {
      return null
    }

    return (
      <React.Fragment>
        <div className="interface_banner ebg_negative">
          { i18n.t("browser_support_warning", { browser }) }
          <button type="button" className="close" onClick={ () => onbrowserNotificationClose() }></button>
        </div>
      </React.Fragment>
    )
  }
}
