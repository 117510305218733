export * from '../../../server/core/errors.ts'

export function match(error, choices) {
  if (!Array.isArray(error)) {
    return choices.unknown(error);
  }

  if (choices[error]) {
    return choices[error](error);
  }

  if (error.length > 1 && choices[error[0]]) {
    return choices[error[0]]([error[0]]);
  }

  return choices.unknown(error);
}
