import React, { useState } from 'react'
import PropTypes from 'prop-types'
import className from 'classnames'
import NotificationsContext from 'common/shared/Notifications/NotificationsContext';
import { GlobalNotifier } from 'common/components/GlobalNotifier';
import LostConnectionMessage from 'common/shared/Connector/LostConnectionMessage';

export function InterfacePage(props) {
  const { children, fullscreen, withSupportChat, noSelect, withActionSpacing, className: extraClassName, connected, ...otherProps } = props;

  const interfacePageClassName = className({
    interface_page: true,
    'interface_page--fullscreen': fullscreen,
    'withSupportChat': (env.CARROTQUEST_ENABLE === true && withSupportChat),
    'withActionSpacing': withActionSpacing,
  }, extraClassName);

  const [notifications, setNotifications] = useState(null);

  return (
    <>
      <div
        className={interfacePageClassName}
        {...otherProps}
      >
        <NotificationsContext.Provider
          value={{ notifications, setNotifications }}
        >
          {children}
        </NotificationsContext.Provider>
      </div>
      <div id="modals"></div>
      <div id="context-menus"></div>
      <LostConnectionMessage connected={connected} />
      <GlobalNotifier/>
    </>
  );
}

InterfacePage.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

InterfacePage.defaultProps = {
  className: ''
};
