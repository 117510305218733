// Получение названия бренда в зависимости от значения в руте
function getBrandName() {
    let BrandName

    switch (env.ULEARN_BRAND) {
        case 'unicraft':
            BrandName = "Unicraft"
            break;

        case 'foxtery':
            BrandName = "Foxtery"
            break;

        case 'flowdemy':
            BrandName = "Flowdemy"
            break;

        default:
            BrandName = "Unicraft"
            break;
    }

    return BrandName
}

export default getBrandName
