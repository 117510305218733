import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from'prop-types'
import className from 'classnames'

export class Modal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    closeable: PropTypes.bool,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    onHide: PropTypes.func,
    isNarrow: PropTypes.bool,
  };

  static defaultProps = {
    closeable: true,
    className: '',
    isNarrow: false,
  };

  constructor(props) {
    super(props);

    this.modalsRoot = window.document.getElementById('modals');
  }

  render() {
    const dialogviewClassName = className({
      dialogview: true,
      dialogview_narrow: this.props.isNarrow,
      dialogview_wide: this.props.isWide,
      dialogview_max: this.props.isMax,
      dialogview_flexview: this.props.isFlexView,
      dialogview_paperstyle: this.props.isPaperStyle,
    });
    
    const containerClassName = className({
      dialogview_container: true,
      dialogview_container_gallery: this.props.isGallery,
      'dialogview_container--white': this.props.isWhite
    }, this.props.className);

    if (!this.props.show) {
      return null;
    }

    return ReactDOM.createPortal(
      <div
        className={containerClassName}
      >
        {
          this.props.isGallery
            ? <button type="button" className="close close--gallery" onClick={this.props.onHide}></button>
            : null
        }
        <div className="dialogview_backdrop" onClick={this.props.onHide}/>
        <div className={dialogviewClassName}>
          {
            this.props.closeable && this.props.onHide && !this.props.isGallery
              ? <button type="button" className="close" onClick={this.props.onHide}/>
              : null
          }
          {this.props.children}
        </div>
      </div>,
      this.modalsRoot
    );
  }
}
