export const system = { // 0**
  invalidApiEvent:      [1, "Invalid API event"],
  unexpectedError:      [2, "Internal server error"],
  invalidDownloader:    [3, "Invalid downloader"],
  licensesLimitReached: [4, "Licenses limit reached"],
  malformedRequest:     [5, "Malformed request"],
  invalitPayload:       [6, "Invalid payload"],
};

export const auth = { // 1**
  unauthenticated:    [100, "Unauthenticated"],
  invalidCredentials: [101, "Invalid credentials"],
  userDeactivated: [102, "User deactivated"],
};

export const uploader = { // 2**

};

export const user = { // 3**
  notFound:          [300, "User not found"],
  emailDuplicated:   [301, "Email already in use"],
  userNameRequired:  [302, "User name required"],
  emailRequired:     [303, "Email required"],
  emailMalformed:    [304, "Malformed email format"],
  invalidUserObject: [305, "Invalid user object"],
  loginDuplicated:   [306, "Login already in use"],
  loginMalformed:    [307, "Malformed login format"],
};

export const student = { // 5**
  courseNotFound: [500, "Course not found for user"],
};

export const userCourse = { // 6**
  notFound:                    [600, "User course not found"],
  invalidElementState:         [601, "User course element state is invalid"],
  duplicateIndividual:         [602, "An attempt to create user course duplicate"],
  individualNotFound:          [603, "Individual user course not found"],
  selfRetake:                  [604, "Self retake prohibited"],
  noAccess:                    [605, "No access to user course"],
  elementNotRewindable:        [606, "Element is not rewindable"],
  durationNotReached:          [607, "Minimal duration for element is not reached"],
  courseElementNotFound:       [613, "Course element in the course not found"],
};

export const course = { // 7**
  notFound: [700, "Course not found"],
};

export const password = { // 8**
  noArgument:         [801, "Argument not sent"],
  emailNotRegistered: [802, "Email not registered"],
  noHashArgument:     [803, "Hash code not sent"],
  noPassword:         [804, "New password not sent"],
  notConfirmPassword: [805, "New password not confirmed"],
  notVerifiedHash:    [806, "Hash code not verified"],
  hashExpired:        [807, "Hash code expired"],
  noOldPassword:      [808, "Old password not set"],
  notSaved:           [810, "New password not saved"],
  invalidData:        [811, "Invalid input data"],
  invalidToken:       [812, "Invalid restore token"],
  invalidPassword:    [813, "Invalid password"],
};

export const downloader = { // 9**
  invalidDownloader:        [901, "Invalid downloader"],
  invalidDownloaderPayload: [902, "Invalid downloader payload"],
};

export const department = { // 10**
  notFound:          [1001, "Department not found"],
  managerIsAssigned: [1002, "Manager has already been assigned"],
  courseIsAssigned:  [1003, "Course has already been assigned"],
  planIsAssigned:    [1004, "Plan has already been assigned"],
  userIsAssigned:    [1005, "User has already been assigned"],
  insufficientPackages: [1006, "Insufficient number of packages"],
  selfRegistrationIsDisabled: [1007, "Self-registration is disabled"],
  selfRegistrationNotStarted: [1008, "Self-registration not started"],
  selfRegistrationWasClosed: [1009, "Self-registration was closed"],
  selfRegistrationUuidValidationError: [1010, "Self-registration uuid format is not valid"],
  departmentIdCantBeEmptyString: [1011, "Department Id can't be empty string"]
};

export const meetup = {
  notFound: [1201, 'Meetup is not found'],
  pruffmeError: [1202, 'Pruffme: Error in request to the server'],
  pruffmeWebinarHashNotReceived: [1203, 'Pruffme: Webinar hash not received'],
}

export const plan = { // 11**
  notFound: [1101, "Plan not found"],
  courseNotAllowedInPlan: [1102, 'Course not allowed in plan'],
  badCourseOrder: [1103, 'Bad course order'],
};
