import io from 'socket.io-client'
import sis from 'socket.io-stream'
import {Event} from "domains/events";
import throttle from 'lodash.throttle';
import { logger } from 'common/lib/logger';
/**
 * Базовый адрес хоста
 *
 * @type {string}
 */
// eslint-disable-next-line no-restricted-globals
export const host = `//${location.host}` + (location.port && !location.host.includes(':') ? ':' + location.port : '');



// Говорим, что запросы отправляет веб-клиент а не МП
document.cookie = 'X-Client-Type=desktop;path=/';
document.cookie = `X-Platform=${window.navigator.platform};path=/`;
document.cookie = `X-Platform-Language=${window.navigator.language};path=/`;
document.cookie = `X-Client-TimeZone-Offset=${new Date().getTimezoneOffset()};path=/`;

/**
 * Соединение
 */
const server = io.connect(host, {
  transports: [
    'websocket',
  ],
});

/**
 * Готовность соединения
 *
 * @type {boolean|Promise}
 */
let ready = new Promise(resolve => {
  // Базовый обработчик подключения
  onConnect(() => {
    resolve();
    ready = true;
  });
});

/**
 * HTTP Request
 *
 * @param path
 * @param payload
 * @param forceGet
 * @return {Promise<any>}
 */
export async function query(path, payload = null, forceGet = false) {
  const request = {
    method: forceGet
      ? 'get'
      : 'post',
    headers: {
      'content-type': 'application/json',
      'authorization': window.localStorage['token']
    }
  };

  if (payload !== null) {
    request.body = JSON.stringify(payload);
  }

  const response = await window.fetch(`${host}/${path}`, request);

  const contentType = response.headers.get('Content-Type');

  const bodyPromise = contentType.indexOf('text/plain') > -1
    ? response.text()
    : response.json();

  if (response.status >= 200 && response.status < 300) {
    return bodyPromise;
  }

  return bodyPromise.then(error => Promise.reject(error));
}

export function subscribe(event, cb) {
  server.on(`pub#${event}`, cb);

  serverEmitPromise('sub', event);
}

export function unsubscribe(event) {
  serverEmitPromise('unsub', event);
}

const apiCalls = new Map();

export function api(event, payload) {
  const key = JSON.stringify([event, payload]);

  if (!apiCalls.has(key)) {
    apiCalls.set(
      key,
      throttle((...args) => {
        return serverEmitPromise(...args).finally(() => {
          apiCalls.delete(key);
        });
      }, 3000)
    );
  } else {
    logger.warn(
      `There is already a pending request for the key ${key.substr(0, 60)}${
        key.length > 103 ? `...${key.substr(-40)}` : ""
      }. It calls more than once in 3 seconds. Check validity of the request.`
    );
  }

  const throttledServerEmitPromise = apiCalls.get(key);

  return throttledServerEmitPromise("api", [
    Event[event],
    payload,
    { pathname: document.location.pathname },
  ]);
}

export function listen(event, cb) {
  server.on(event, cb);
}

export function initDownload(downloaderName, payload) {
  return serverEmitPromise('dl', [downloaderName, payload]);
}

// SOCKET EVENTS

export function onDownloadStart(cb) {
  server.on('dl:start', cb);
}

export function onDownloadFinish(cb) {
  server.on('dl:finish', cb);
}

export function onDownloadData(cb) {
  server.on('dl:data', cb);
}

export function onDownloadError(cb) {
  server.on('dl:error', cb);
}

/**
 * Подключение
 *
 * @param cb
 */
export function onConnect(cb) {
  server.on('connect', cb);
}

/**
 * Отключение
 *
 * @param cb
 */
export function onDisconnect(cb) {
  server.on('disconnect', cb);
}

/**
 * Ошибка при подключении
 *
 * @param cb
 */
export function onConnectError(cb) {
  server.on('connect_error', cb);
  server.on('reconnect_error', cb);
}

/**
 * Переподключение
 *
 * @param cb
 */
export function onReconnect(cb) {
  server.on('reconnect', cb);
}

/**
 * Обвязывающий промис для вызова только после успешного подключения к серверу
 *
 * @param executor
 * @returns {Promise}
 */
function readyPromise(executor) {
  const executorPromise = () => new Promise((resolve, reject) => {
    executor(resolve, reject);
  });

  if (ready === true) {
    return executorPromise();
  } else {
    return ready.then(executorPromise);
  }
}

/**
 * Вызов сервера с обратным вызовом через промис
 *
 * @param eventName
 * @param payload
 * @returns {Promise}
 */
export function serverEmitPromise(eventName, payload) {
  return readyPromise((resolve, reject) => {
    server.emit(eventName, payload, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

/**
 * Создание аплоадера
 *
 * @returns {Promise}
 */
export function createUploader() {
  return readyPromise(resolve => resolve(sis(server)));
}
