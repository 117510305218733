import authenticator, { attachAuthenticator } from './auth'
import restore from './restore'
import registration from './registration'

const ducks = {
  authenticator,
  restore,
  registration
};

export function attachServer(store) {
  attachAuthenticator(store);
}

export default ducks
