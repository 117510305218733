import debounce from 'lodash.debounce';

export default function setupAdjuster(role) {
  window.isIPhone = /iPhone/.test(navigator.userAgent);

  const viewport = window.document.getElementById('viewport');

  const adjust = debounce(() => {

    const isCompact = (role === 'student') || (!role)

    const viewportMin = isCompact
      ? 400
      : 1000;

    const viewportWidth = window.screen.availWidth < viewportMin
      ? viewportMin
      : 'device-width';

    const scale = window.screen.availWidth < viewportMin
      ? window.screen.availWidth / viewportMin
      : 1;

    // console.log(`-- setupAdjuster
    //   viewportWidth: ${viewportWidth} 
    //   scale: ${scale}
    //   window.screen.availWidth: ${window.screen.availWidth}
    // `);

    const viewportParams = [
      `width=${viewportWidth}`,
      `initial-scale=${scale}`,
      `minimum-scale=0.25`,
      `maximum-scale=1.5`,
      `user-scalable=${isCompact ? 'no' : 'yes'}`
    ];

    // if (window.isIPhone) {
    //   if (window.__is_student_course_advancer_now) {
    //     viewportParams.push(`initial-scale=0.5`);
    //   } else {
    //     // viewportParams.push(`initial-scale=1`);
    //   }
    // }

    viewport.content = viewportParams.join(', ');

    window.document.body.scrollTop = 0;
    window.document.body.scrollLeft = 0;
  }, 300)

  adjust();

  window.addEventListener('resize', adjust);
}
