import 'common/lib/styles'
import React from 'react'
import ReactDOM from 'react-dom'
import { Router, Route, IndexRoute, IndexRedirect } from 'react-router'
import { Provider } from 'react-redux'
import setupViewportAdjuster from 'common/lib/viewportAdjuster'
import createStore from './store'
import MainContainer from './components/MainContrainer'
import AuthContainer from './components/AuthContainer'
import Init from './components/restore/Init'
import Registration from './components/Registration'
import Finish from './components/restore/Finish'
import setTitle from "../../common/lib/setTitle";

const { store, history } = createStore();

setTitle();

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Route path="/" >
        <IndexRedirect to="/login" />

        <Route path="login" component={MainContainer}>
          <IndexRoute component={AuthContainer} />
        </Route>

        <Route path="registration" component={MainContainer}>
          <IndexRoute component={Registration} />
          <Route path=":departmentId" component={Registration} />
        </Route>

        <Route path="role" component={MainContainer}>
          <IndexRoute component={AuthContainer} />
        </Route>

        <Route path="restore" component={MainContainer}>
          <IndexRoute component={Init} />
          <Route path=":token" component={Finish} />
          <Route path=":token/:activation" component={Finish} />
        </Route>
      </Route>
    </Router>
  </Provider>,
  document.getElementById('root'),
);

setupViewportAdjuster(null);
