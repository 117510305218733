import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import i18n from 'i18n/locale';

export default class LostConnectionMessage extends Component {
  static propTypes = {};

  state = {
    connected: true,
    show: ''
  };

  componentWillReceiveProps(props) {
    if (props.connected === 'lost' && this.state.connected === true) {
      this.setState({ connected: false, show: 'disconected' })
    }

    if (props.connected === true && (this.state.connected === 'lost' || this.state.connected === false)) {
      if (props.needsReload) {
        this.setState({ connected: true, show: 'restored' })
      } else {
        this.setState({ connected: true, show: '' })
      }
    }
  }

  renderMessage() {

    let message = '';

    if (this.state.show === 'restored') {
      message = (
        <div className="lostconnection">
          <span className="lostconnection_text">{ i18n.t('После восстановления соединения рекомендуется перезагрузить страницу Если у вас есть несохраненные данные — скопируйте их') }</span>
          <button type='button' className='button button_warning form_el_small' onClick={() => window.location.reload()}>{i18n.t('Перезагрузить')}</button>
        </div>
      );

      return ReactDOM.createPortal(message, this._messageRoot)
    }

    if (this.state.show === 'disconected') {

      message = (
        <div className="lostconnection lostconnection--small">
          <span className="lostconnection_text">{ i18n.t('Подключение') }...</span>
        </div>
      );

      // message = (
      //   <div className="lostconnection">
      //     <span className="lostconnection_text">{ i18n.t('Потеряно соединение с сервером') }</span>
      //   </div>
      // );

      return ReactDOM.createPortal(message, this._messageRoot)
    }

    return null

  }

  render() {
    return <div id='messageRoot' ref={(ref) => this._messageRoot = ref}>{this.renderMessage()}</div>
  }
}
