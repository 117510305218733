import { showErrorNotification, showNotification } from 'common/components/GlobalNotifier'
import i18n from 'i18n/locale';

/**
 * Предназначена для копирования в буфер получаемый контент
 */
export async function copyToClipboard(textToCopy) {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    try {
      await navigator.clipboard.writeText(textToCopy);
      showNotification({ title: i18n.t('Скопировано в буфер') });
    } catch (err) {
      showErrorNotification({ title: i18n.t('Что-то пошло не так') });
    }
  } else {
    // text area method
    let textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    // make the textarea out of viewport
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
      // here the magic happens
      document.execCommand('copy')
        ? res()
        : rej();
      textArea.remove();
      showNotification({ title: i18n.t('Скопировано в буфер') })
    });
  }
}
