import { api, subscribe } from './index'
import { Event } from "domains/events";

export function checkExist(departmentId) {
  return api(Event.DepartmentsCheckExist, departmentId);
}

export function save(department) {
  return api(Event.DepartmentSave, department);
}

export function remove(departmentId) {
  return api(Event.DepartmentHardDelete, departmentId);
}

export function getAll() {
  return api(Event.DepartmentsGetAll);
}

export function getDepartmentsWithUsers() {
  return api(Event.DepartmentsWithUsers);
}

export function getAllForManager(){
  return api(Event.DepartmentsGetForManager);
}

export function getAllForTrainer(){
  return api(Event.DepartmentsGetForTrainer);
}

export function assignManager(user_id, department_id) {
  return api(Event.DepartmentManagerAssign, { user_id, department_id });
}
export function removeManager(user_id, department_id) {
  return api(Event.DepartmentManagerRemove, { user_id, department_id });
}

export function assignCourse(course_id, department_id, mode, options) {
  return api(Event.DepartmentCourseAssign, { course_id, department_id, mode, options });
}
export function removeCourse(course_id, department_id) {
  return api(Event.DepartmentCourseRemove, { course_id, department_id });
}

export function assignMeetUp(meetup_id, department_id) {
  return api(Event.DepartmentMeetUpAssign, { meetup_id, department_id });
}
export function removeMeetUp(meetup_id, department_id) {
  return api(Event.DepartmentMeetUpRemove, { meetup_id, department_id });
}

export function assignPlan(plan_id, department_id, mode, options) {
  return api(Event.DepartmentPlanAssign, { plan_id, department_id, mode, options });
}
export function removePlan(plan_id, department_id) {
  return api(Event.DepartmentPlanRemove, { plan_id, department_id });
}

export function assignUser(user_id, department_id, options) {
  return api(Event.DepartmentUserAssign, { user_id, department_id, options });
}
export function removeUser(user_id, department_id) {
  return api(Event.DepartmentUserRemove, { user_id, department_id });
}

export function assignTeacher(user_id, department_id) {
  return api(Event.DepartmentTeacherAssign, { user_id, department_id });
}
export function removeTeacher(user_id, department_id) {
  return api(Event.DepartmentTeacherRemove, { user_id, department_id });
}

export function listenCreated(cb) {
  subscribe(Event.DepartmentCreated, cb);
}

export function listenCreatedForManager(id, cb) {
  subscribe(`${Event.DepartmentCreated}:${id}`, cb);
}

export function listenUpdated(cb) {
  subscribe(Event.DepartmentUpdated, cb);
}

export function listenDeleted(cb) {
  subscribe(Event.DepartmentDeleted, cb);
}
