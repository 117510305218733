import * as authApi from 'common/server/auth'
import * as auth from './auth'

import i18n from 'i18n/locale';

import {
  showSuccessNotification,
  showWarningNotification
} from 'common/components/GlobalNotifier'

const initialState = {
  initializing: false,
  initialized: false,
  emailCorrect: null,

  resetTokenValidating: null,
  resetTokenValid: null,

  finishing: false,
  user: null,
};

const SET_INITIALIZING = 'uc/lms/main/restore/setInitializing';
function doSetInitializing(state, { initializing = true }) {
  return {
    ...state,
    initializing
  }
}

const SET_INITIALIZED = 'uc/lms/main/restore/setInitialized';
function doSetInitialized(state, { initialized = true }) {
  return {
    ...state,
    initialized,
    initializing: false,
    emailCorrect: null
  }
}

const SET_EMAIL_INCORRECT = 'uc/lms/main/restore/setEmailIncorrect';
function doSetEmailIncorrect(state) {
  return {
    ...state,
    initialized: false,
    emailCorrect: false
  }
}

const SET_TOKEN_VALIDATING = 'uc/lms/main/restore/setTokenValidating';
function doSetTokenValidating(state) {
  return {
    ...state,
    resetTokenValidating: true
  }
}

const SET_TOKEN_VALID = 'uc/lms/main/restore/setTokenValid';
function doSetTokenValid(state, { valid }) {
  return {
    ...state,
    resetTokenValid: valid,
    resetTokenValidating: false
  }
}

const SET_FINISHING = 'uc/lms/main/restore/setFinishing';
function doSetFinishing(state, { finishing = true }) {
  return {
    ...state,
    finishing
  }
}

const SET_USER = 'uc/lms/main/restore/setUser';
function doSetUser(state, { user }) {
  return {
    ...state,
    user
  }
}

export default function restore(state = initialState, action) {
  switch (action.type) {
    case SET_INITIALIZING: return doSetInitializing(state, action);
    case SET_INITIALIZED: return doSetInitialized(state, action);
    case SET_EMAIL_INCORRECT: return doSetEmailIncorrect(state);

    case SET_TOKEN_VALIDATING: return doSetTokenValidating(state);
    case SET_TOKEN_VALID: return doSetTokenValid(state, action);

    case SET_FINISHING: return doSetFinishing(state, action);
    case SET_USER: return doSetUser(state, action);

    default: return state;
  }
}

/**
 * @api
 * @param email
 * @returns {function(*)}
 */
export function init(email, showNotification = false) {
  return async dispatch => {
    dispatch({
      type: SET_INITIALIZING,
      initializing: true
    });

    let initialized = false;

    try {
      await authApi.restoreInit(email);

      initialized = true;
    } catch (error) {
      dispatch({
        type: SET_EMAIL_INCORRECT
      });

      if (showNotification) {
        showWarningNotification({
          title: i18n.t('Что-то пошло не так')
        });
      }
    }

    dispatch({
      type: SET_INITIALIZED,
      initialized
    });

    if (showNotification) {
      showSuccessNotification({
        title: i18n.t('Сообщение о восстановлении пароля отправлено'),
      });
    }
  }
}

/**
 * @api
 * @param emails
 * @returns {function(*)}
 */
export function initBulk(emails, showNotification = false) {
  return async dispatch => {
    dispatch({
      type: SET_INITIALIZING,
      initializing: true
    });

    let initialized = false;
    let failed = false;

    for (const email of emails) {
      try {
        await authApi.restoreInit(email);
  
        initialized = true;
      } catch (error) {
        dispatch({
          type: SET_EMAIL_INCORRECT
        });

        failed = true
  
        if (showNotification) {
          showWarningNotification({
            title: `${i18n.t('Что-то пошло не так')}: ${email}`
          });
        }
      }
    }

    if (showNotification) {
      if (!failed) showSuccessNotification({
        title: i18n.t('Сообщение о восстановлении пароля отправлено'),
      });
    }

    dispatch({
      type: SET_INITIALIZED,
      initialized
    });
  }
}

/**
 * @api
 * @returns {{type: string, initialized: boolean}}
 */
export function reset() {
  return {
    type: SET_INITIALIZED,
    initialized: false
  }
}

/**
 * @api
 * @param token
 * @returns {function(*)}
 */
export function verify(token) {
  return async dispatch => {
    dispatch({
      type: SET_TOKEN_VALIDATING
    });

    dispatch({
      type: SET_TOKEN_VALID,
      valid: await authApi.restoreVerify(token)
    });
  }
}

export function finish(token, password, repeat) {
  return async dispatch => {
    dispatch({
      type: SET_FINISHING,
      finishing: true
    });

    let response = null;

    try {
      response = await authApi.restoreFinish(token, password, repeat);
    } catch (e) {
      dispatch({
        type: SET_TOKEN_VALID,
        valid: false
      });
    }

    if (response !== null) {
      dispatch({
        type: SET_USER,
        user: response.user
      });

      dispatch(auth.setToken(response.token));
    }

    dispatch({
      type: SET_FINISHING,
      finishing: false
    });
  }
}
