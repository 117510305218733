import i18n from "i18n/locale";
import { logger } from 'common/lib/logger'
import * as coreError from "common/lib/coreError";
import * as usersApi from "common/server/user";
import * as departmenstsApi from "common/server/departments";
import { SET_AUTHENTICATED } from 'apps/main/store/ducks/auth'
import {
  showErrorNotification,
} from "common/components/GlobalNotifier";
import {
  authenticate
} from 'common/server/auth'

const initialState = {
  initializing: false,
  hasError: false,
  errorMessage: "",
  registeredUser: null,
  isRegistrationSuccess: false,
  departmentTitle: '',
  passwordRestoreSuggestion: false
};

const SET_INITIALIZING = "uc/lms/main/registration/setInitializing";
function doSetInitializing(state, { initializing = true }) {
  return {
    ...state,
    initializing,
  };
}

const SET_VALID_DEPARTMENT = "uc/lms/main/registration/setValidDepartment";
function doSetValidDepartment(state, { departmentTitle }) {
  return {
    ...state,
    departmentTitle,
  };
}

const SET_SUCCESS_REGISTRATION = "uc/lms/main/registration/setSuccessRegistration";
function doSetSuccessAsign(state, { isRegistrationSuccess = true, registeredUser }) {
  return {
    ...state,
    isRegistrationSuccess,
    registeredUser
  };
}

const SET_REGISTRATION_ERROR = "uc/lms/main/registration/setRegistrationError";
function doSetRegistrationError(state, { errorMessage, passwordRestoreSuggestion }) {
  return {
    ...state,
    initializing: false,
    hasError: true,
    errorMessage,
    passwordRestoreSuggestion
  };
}

export function validateDepartmentIdOnLoad(departmentId) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_INITIALIZING,
      initializing: true,
    });

    dispatch({
      type: SET_AUTHENTICATED,
      preventRedirection: true,
    });

    const departmentTitle = await departmenstsApi
      .checkExist(departmentId)
      .catch((error) => logger.error("Self-registration:", error));
    if (!departmentTitle) {
      dispatch({
        type: SET_REGISTRATION_ERROR,
        errorMessage: i18n.t("Обратитесь к администратору платформы"),
      });
    } else {
      dispatch({
        type: SET_VALID_DEPARTMENT,
        departmentTitle,
      });

      const state = getState();      
      const user = await authenticate(state.authenticator.token).catch((error) => logger.error("Self-registration:", error));

      if (user) {
        await registrate(departmentId, { name: user.name, email: user.email}, {userId: user.id, departmentTitle})(
          dispatch
        );
      }
    }

    dispatch({
      type: SET_INITIALIZING,
      initializing: false,
    });
  };
}

export function registrate(departmentId, user, options) {
  return async (dispatch) => {
    dispatch({
      type: SET_INITIALIZING,
      initializing: true,
    });

    let registeredUser = await usersApi
      .selfRegistrationForDepartment(departmentId, user)
      .catch((error) => {
        logger.error('Self-registration:', error);
        coreError.match(error, {
          [coreError.department.userIsAssigned]: () =>
            dispatch({
              type: SET_REGISTRATION_ERROR,
              errorMessage:
                options && options.userId
                  ? i18n.t("Вы авторизованы как", {name: user.name, email: user.email, group: options.departmentTitle})
                  : i18n.t("Ссылка для пользователя", {name: user.name, email: user.email}),
              passwordRestoreSuggestion: !(options && options.userId),
            }),
          [coreError.department.notFound]: () =>
            showErrorNotification({
              title: i18n.t(
                "Ссылка не действительна"
              ),
            }),
          unknown: () => {
            dispatch({
              type: SET_REGISTRATION_ERROR,
              errorMessage: i18n.t("Обратитесь к администратору платформы"),
            });
          },
        });
        return null;
      });

    if (registeredUser !== null) {
      dispatch({
        type: SET_SUCCESS_REGISTRATION,
        registeredUser
      })
    };

    dispatch({
      type: SET_INITIALIZING,
      initializing: false,
    });
  };
}

export default function registration(state = initialState, action) {
  switch (action.type) {
    case SET_INITIALIZING:
      return doSetInitializing(state, action);
    case SET_REGISTRATION_ERROR:
      return doSetRegistrationError(state, action);
    case SET_VALID_DEPARTMENT:
      return doSetValidDepartment(state, action);
    case SET_SUCCESS_REGISTRATION:
      return doSetSuccessAsign(state, action);
    default:
      return state;
  }
}
