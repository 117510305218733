import React from 'react'
import PropTypes from 'prop-types'
import { copyToClipboard } from 'common/lib/copyToClipboard';
import i18n from 'i18n/locale';

export function PlatformVerion(props) {

    if (!env.ULEARN_VERSION) return ''

    let brandLink, brandName

    switch (env.ULEARN_BRAND) {
        case 'unicraft':
            brandLink = 'https://www.unicraft.org'
            brandName = 'Unicraft'
            break;

        case 'flowdemy':
        case 'foxtery':
            brandLink = 'https://www.foxtery.com'
            brandName = 'Foxtery'
            break;

        default:
            brandLink = 'https://www.unicraft.org'
            brandName = 'Unicraft'
            break;
    }

    return (
        <div className="platform_version">
            <div className="clickable" onClick={() => copyToClipboard(`${env.ULEARN_ID} ${env.ULEARN_HOST} ${env.ULEARN_VERSION} `)}>
                <span>{env.ULEARN_ID || 'uLearn'}</span>
                <span>{env.ULEARN_VERSION}</span>
            </div>
            {(props.withMadeBy && !env.ULEARN_UNICRAFT_LABEL_HIDE) && <div><a href={`${brandLink}?utm_source=clients_pl&utm_medium=foot&utm_campaign=${window.location.hostname}`} target="_blank" rel="noreferrer">{i18n.t("Сделано на brand", { brand: brandName })}</a></div>}
        </div>
    );
}

PlatformVerion.propTypes = {
    withMadeBy: PropTypes.bool,
};

PlatformVerion.defaultProps = {
    withMadeBy: false,
};
