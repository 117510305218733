import {
  query,
  listen,
  serverEmitPromise
} from './index'

export function acquireToken(email, password) {
  const payload = { email, password };

  return query(`api/v2/auth/credentials`, payload).then(res => res.token);
}

/**
 * @api
 * @param email
 * @returns {Promise.<void>}
 */
export async function restoreInit(email) {
  const [error, success] = await query('api/v2/restore/init', { email });

  if (error || !success) {
    throw error;
  }
}

/**
 * @api
 * @param token
 * @param password
 * @param repeat
 * @returns {Promise<{token: string, user: User}>}
 */
export async function restoreFinish(token, password, repeat) {
  const [error, response] = await query('api/v2/restore/finish', {
    token,
    repeat,
    password
  });

  if (error || !response) {
    throw error;
  }

  return response;
}

/**
 * @api
 * @param token
 * @returns {Promise.<boolean|*>}
 */
export async function restoreVerify(token) {
  const [error, success] = await query('api/v2/restore/verify', { token });

  return !error && success;
}

export function authenticate(token) {

  let timeZone

  if (Intl.DateTimeFormat()) {
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  return serverEmitPromise('auth', {token, timeZone});
}

/**
 * @api
 * @param cb
 */
export function onAuthenticated(cb) {
  listen('authenticated', cb);
}

export function onDeleted(cb) {
  listen('deleted', cb);
}

/**
 * @api
 * @private
 * @param cb
 */
export function onContentAccessToken(cb) {
  listen('contentAccessToken', cb);
}

export function onCarrotquestToken(cb) {
  listen('carrotquestToken', cb);
}