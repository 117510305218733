import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import * as restore from 'apps/main/store/ducks/restore'
import { FormBlock } from 'common/components/ui/forms/FormBlock'
import { Button } from 'common/components/ui/Button'
import { Form } from 'common/components/ui/forms/Form'

import i18n from 'i18n/locale';

class Init extends Component {
  static propTypes = {
    initializing: PropTypes.bool.isRequired,
    initialized: PropTypes.bool.isRequired,
    emailCorrect: PropTypes.bool,

    init: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  };

  static defaultProps = {
    emailCorrect: null,
  };

  state = {
    email: '',
    emailValid: false
  };

  onChangeEmail = ({ target }) => {
    this.setState({
      email: target.value,
      emailValid: target.checkValidity()
    });
  }

  onInitialize = () => {
    if (!this.state.emailValid || this.props.initializing) {
      return;
    }

    this.props.init(this.state.email.toLowerCase());
  }

  onReset = () => {
    this.setState({
      email: '',
      emailValid: false
    });
    this.props.reset();
  }

  renderInitialized() {
    return (
      <div className="authblock">
        <div className="authblock_header">
          <h1>
            {i18n.t('Восстановление доступа')}
          </h1>
          <p className="hugetext">
            {i18n.t('На указанный адрес электронной почты будет отправлена ссылка для сброса пароля и указания нового')}
          </p>
        </div>

        <div className="row">
          <div className="col">
            <p>
              {i18n.t('Письмо со ссылкой для сброса может прийти с задержкой до 10 минут')}
              . {i18n.t('Если письмо не пришло')}
               {i18n.t('проверьте папку со спамом')} {i18n.t('или')} <button type="button" className="dottedlink" onClick={this.onReset}>
              {i18n.t('повторите запрос')}
            </button> {i18n.t('восстановления')}
              . {i18n.t('В противном случае обратитесь к вашему руководителю')}.
            </p>
            <p style={{marginTop: "2rem"}}>
              <Link className="authblock_restore_link" to="/">{i18n.t('Вернуться на')} {i18n.t('страницу авторизации')}</Link>
            </p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.initialized) {
      return this.renderInitialized();
    }

    return (
      <div className="authblock">
        <div className="authblock_header">
          <h1>
            {i18n.t('Восстановление доступа')}
          </h1>
          <p className="hugetext">
            {i18n.t('Укажите адрес электронной почты указанный при регистрации в системе')}
          </p>
        </div>

        <div className="authblock_form">
          {
            this.props.emailCorrect === false
              ? (
                <div className="form_message form_message_error">
                  {i18n.t('Пользователь не найден')}
                </div>
              )
              : null
          }

          <Form onSubmit={this.onInitialize}>
            <FormBlock>
              <input
                type="email"
                className={`text form_el_big tac ${this.props.emailCorrect === false ? 'error' : ''}`}
                value={this.state.email}
                required
                onChange={this.onChangeEmail}
                placeholder="Email"
              />
            </FormBlock>

            <FormBlock>
              <Button
                big
                maxWidth
                blue
                onClick={this.onInitialize}
                disabled={!this.state.emailValid || this.props.initializing}
              >
                {i18n.t('Восстановить')}
              </Button>
              <p style={{marginTop: "2rem"}}>
                <Link className="authblock_restore_link" to="/">{i18n.t('Вернуться на')} {i18n.t('страницу авторизации')}</Link>
              </p>
            </FormBlock>
          </Form>
        </div>
      </div>
    );
  }
}

export default connect(
  state => state.restore,
  dispatch => ({
    init: email => dispatch(restore.init(email)),
    reset: () => dispatch(restore.reset()),
  })
)(Init);
