import {DependentEntities} from "./meetup/types";

export const Event = {
  NewsGetAll: "NewsGetAll",
  NewsSave: "NewsSave",
  NewsRemove: "NewsRemove",
  MarkNewsAsRead: "MarkNewsAsRead",

  PlansGetAll: "PlansGetAll",
  PlanSave: "PlanSave",
  PlanHardDelete: "PlanHardDelete",
  PlanCreated: "PlanCreated",
  PlanUpdated: "PlanUpdated",
  PlanDeleted: "PlanDeleted",

  CourseGet: "CourseGet",
  CoursesGetByIds: "CoursesGetByIds",
  CoursesGetAll: "CoursesGetAll",
  CoursesGetAllShallow: "CoursesGetAllShallow",
  CoursesGetAllByIds: "CoursesGetAllByIds",
  CourseSave: "CourseSave",
  CourseDuplicate: "CourseDuplicate",
  CourseHardDelete: "CourseHardDelete",
  CourseCreated: "CourseCreated",
  CourseUpdated: "CourseUpdated",
  CourseDeleted: "CourseDeleted",

  CourseElementsGetAll: "CourseElementsGetAll",
  CourseElementSave: "CourseElementSave",
  CourseElementsSequenceSave: "CourseElementsSequenceSave",
  CourseElementDelete: "CourseElementDelete",
  CourseElementUpdated: "CourseElementUpdated",
  CourseElementCreated: "CourseElementCreated",
  CourseElementDeleted: "CourseElementDeleted",

  CourseCategoriesGetAll: "CourseCategoriesGetAll",
  CourseCategorySave: "CourseCategorySave",
  CourseCategoryUpdated: "CourseCategoryUpdated",
  CourseCategoryDelete: "CourseCategoryDelete",
  CourseCategoryDeleted: "CourseCategoryDeleted",

  CourseTeachersGetAll: "CourseTeachersGetAll",
  CourseTeacherAdd: "CourseTeacherAdd",
  CourseTeacherAdded: "CourseTeacherAdded",
  CourseTeacherDelete: "CourseTeacherDelete",
  CourseTeacherDeleted: "CourseTeacherDeleted",
  CourseGetAssignedUsers: "CourseGetAssignedUsers",
  TeachersVerifyCreated: "TeachersVerifyCreated",
  TeachersVerifyUpdated: "TeachersVerifyUpdated",
  TeachersVerifyDeleted: "TeachersVerifyDeleted",

  UserPlansGetAll: "UserPlansGetAll",
  UserPlanCreate: "UserPlanCreate",
  UsersPlanCreate: "UsersPlanCreate",
  UserPlanUpdate: "UserPlanUpdate",
  UserPlanDelete: "UserPlanDelete",
  UserPlanCreated: "UserPlanCreated",
  UserPlanDeleted: "UserPlanDeleted",
  UserPlanBlock: "UserPlanBlock",
  UserPlanUnblock: "UserPlanUnblock",

  UserCoursesGetAll: "UserCoursesGetAll",
  UserCoursesGetForUser: "UserCoursesGetForUser",
  UserCoursesGetForCurrentUser: "UserCoursesGetForCurrentUser",
  UserCoursesGetUsersIdsForCourse: "UserCoursesGetUsersIdsForCourse",

  UserCourseIndividualsGetAllForCourse: "UserCourseIndividualsGetAllForCourse",
  UserCourseIndividualsGetAllForUser: "UserCourseIndividualsGetAllForUser",
  UserCourseIndividualAssign: "UserCourseIndividualAssign",
  UserCourseIndividualRemove: "UserCourseIndividualRemove",
  UserCourseIndividualAssigned: "UserCourseIndividualAssigned",
  UserCourseIndividualRemoved: "UserCourseIndividualRemoved",
  UserCourseIndividualBulk: "UserCourseIndividualBulk",

  UserCourseUpdated: "UserCourseUpdated",
  UserCourseRetake: "UserCourseRetake",
  UserCourseAccept: "UserCourseAccept",
  UserCourseReset: "UserCourseReset",
  UserCourseStart: "UserCourseStart",
  UserCourseBlock: "UserCourseBlock",
  UserCourseUnblock: "UserCourseUnblock",
  UserCourseElementRewind: "UserCourseElementRewind",
  UserCourseElementRewindBulk: "UserCourseElementRewindBulk",
  UserCourseElementStart: "UserCourseElementStart",
  UserCourseElementComplete: "UserCourseElementComplete",
  UserCourseElementConfirm: "UserCourseElementConfirm",
  UserCourseElementDecline: "UserCourseElementDecline",
  UserCourseElementFail: "UserCourseElementFail",
  UserCourseElementSetSuspend: "UserCourseElementSetSuspend",
  UserCourseElementSetCmi: "UserCourseElementSetCmi",
  UserCourseQuestionCommitReview: "UserCourseQuestionCommitReview",
  UserCourseSetNewDeadline: "UserCourseSetNewDeadline",
  
  CertUpdateRequest: "CertUpdateRequest",

  GetServerTime: "GetServerTime",

  PointsDefaultGet: "PointsDefaultGet",
  PointsDefaultReset: "PointsDefaultReset",

  UserCourseStateUpdate: "UserCourseStateUpdate",
  UserCourseRateSet: "UserCourseRateSet",

  UsersCountAll: "UsersCountAll",
  UsersGetAll: "UsersGetAll",
  UserGet: "UserGet",
  UserUpdate: "UserUpdate",
  UserUpdatePassword: "UserUpdatePassword",
  UserUpdateForDepartment: "UserUpdateForDepartment",
  UserSelfRegistration: "UserSelfRegistration",
  UserSelfUpdate: "UserSelfUpdate",
  UserSelfUpdateCheckEmail: "UserSelfUpdateCheckEmail",
  UserHardDelete: "UserHardDelete",
  UserRestoreDeleted: "UserRestoreDeleted",
  UserUpdated: "UserUpdated",
  UserDisconnected: "UserDisconnected",
  UserInvite: "UserInvite",
  UserInviteBulk: "UserInviteBulk",
  SetUserResetToken: "SetUserResetToken",
  UserUpdateFields: "UserUpdateFields",
  UserActivationToggle: "UserActivationToggle",

  CoursesGetAllForTrainer: "CoursesGetAllForTrainer",
  PlansGetAllForTrainer: "PlansGetAllForTrainer",
  UsersGetAllForTrainer: "UsersGetAllForTrainer",
  UsersCoursesHistoryGetForTrainer: "UsersCoursesHistoryGetForTrainer",
  UsersCoursesFailedGetForTrainer: "UsersCoursesFailedGetForTrainer",
  StatsCoursesTableForTrainer: "StatsCoursesTableForTrainer",
  StatsCoursePageForTrainer: "StatsCoursePageForTrainer",

  StatsUsersTable: "StatsUsersTable",
  StatsUserPage: "StatsUserPage",
  StatsCoursesTable: "StatsCoursesTable",
  StatsCoursePage: "StatsCoursePage",
  StatsGetCoursePage: "StatsGetCoursePage",
  StatsPersonalUserPage: "StatsPersonalUserPage",
  UserPlanStats: "UserPlanStats",
  MetricsStats: "MetricsStats",

  PlansForDepartmentGetAll: "PlansForDepartmentGetAll",
  CoursesForDepartmentGetAll: "CoursesForDepartmentGetAll",
  UsersPlansForDepartmentGetAll: "UsersPlansForDepartmentGetAll",

  StatsForDepartmentUserPage: "StatsForDepartmentUserPage",
  StatsForDepartmentUsersTable: "StatsForDepartmentUsersTable",
  StatsForDepartmentCoursePage: "StatsForDepartmentCoursePage",
  StatsForDepartmentCoursesTable: "StatsForDepartmentCoursesTable",

  DepartmentsCheckExist: "DepartmentsCheckExist",
  DepartmentsGetAll: "DepartmentsGetAll",
  DepartmentsGetForManager: "DepartmentsGetForManager",
  DepartmentsGetForTrainer: "DepartmentsGetForTrainer",
  DepartmentGet: "DepartmentGet",
  DepartmentSave: "DepartmentSave",
  DepartmentCreated: "DepartmentCreated",
  DepartmentUpdated: "DepartmentUpdated",
  DepartmentHardDelete: "DepartmentHardDelete",
  DepartmentDeleted: "DepartmentDeleted",
  DepartmentsWithUsers: "DepartmentsWithUsers",

  DepartmentManagerAssign: "DepartmentManagerAssign",
  DepartmentManagerRemove: "DepartmentManagerRemove",

  DepartmentCourseAssign: "DepartmentCourseAssign",
  DepartmentCourseRemove: "DepartmentCourseRemove",

  DepartmentMeetUpAssign: "DepartmentMeetUpAssign",
  DepartmentMeetUpRemove: "DepartmentMeetUpRemove",

  DepartmentTeacherAssign: "DepartmentTeacherAssign",
  DepartmentTeacherRemove: "DepartmentTeacherRemove",

  DepartmentPlanAssign: "DepartmentPlanAssign",
  DepartmentPlanRemove: "DepartmentPlanRemove",

  DepartmentUserAssign: "DepartmentUserAssign",
  DepartmentUserRemove: "DepartmentUserRemove",

  LegitimacyRetrieve: "LegitimacyRetrieve",
  LegitimacyUpdated: "LegitimacyUpdated",

  TrainerData: "TrainerData",
  TrainerUserCourse: "TrainerUserCourse",
  StudentData: "StudentData",
  StudentDataWebApp: "StudentDataWebApp",

  GlossaryGetAll: "GlossaryGetAll",
  GlossarySave: "GlossarySave",
  GlossaryDelete: "GlossaryDelete",
  GlossaryCreated: "GlossaryCreated",
  GlossaryUpdated: "GlossaryUpdated",
  GlossaryDeleted: "GlossaryDeleted",

  CompletionGetAll: "CompletionGetAll",
  CompletionSave: "CompletionSave",
  CompletionDelete: "CompletionDelete",
  CompletionCreated: "CompletionCreated",
  CompletionUpdated: "CompletionUpdated",
  CompletionDeleted: "CompletionDeleted",

  TestsCompetionsGetById: "TestsCompetionsGetById",
  LessonsCompetionsGetById: "LessonsCompetionsGetById",
  QuestionLessonsGetById: "QuestionLessonsGetById",

  PackageGetAll: "PackageGetAll",
  PackageSave: "PackageSave",
  PackageDelete: "PackageDelete",
  PackageCreated: "PackageCreated",
  PackageUpdated: "PackageUpdated",
  PackageDeleted: "PackageDeleted",

  RequestGetAll: "RequestGetAll",
  RequestGetAllForDepartment: "RequestGetAllForDepartment",
  RequestGetForStudent: "RequestGetForStudent",
  RequestSave: "RequestSave",
  RequestAccept: "RequestAccept",
  RequestReject: "RequestReject",
  RequestDelete: "RequestDelete",
  RequestCreated: "RequestCreated",
  RequestUpdated: "RequestUpdated",
  RequestDeleted: "RequestDeleted",
  NewsCreated: "NewsCreated",
  NewsUpdated: "NewsUpdated",
  NewsDeleted: "NewsDeleted",

  FieldsGet: "FieldsGet",
  FieldsGetAll: "FieldsGetAll",
  FieldsSave: "FieldsSave",
  FieldsCreated: "FieldsCreated",
  FieldsUpdated: "FieldsUpdated",
  FieldsDeleted: "FieldsDeleted",
  FieldsGetUserData: "FieldsGetUserData",

  // Root events
  Restart: "Restart",
  RestartPlatform: "RestartPlatform",
  ResetConfig: "ResetConfig",
  SaveCofig: "SaveCofig",
  GetConfig: "GetConfig",
  GetBranding: "GetBranding",
  SaveBranding: "SaveBranding",
  ConfigUpdated: "ConfigUpdated",
  DownloadLogs: "DownloadLogs",

  FirebaseTokenSave: "FirebaseTokenSave",

  // Notifications
  NotificationsGetSettings: "NotificationsGetSettings",
  NotificationsGetAllForUser: "NotificationsGetAllForUser",
  NotificationsToggleOne: "NotificationsToggleOne",
  NotificationsMarkAllAsRead: "NotificationsMarkAllAsRead",
  NotificationsSend: "NotificationsSend",

  ChangesGetByIds: "ChangesGetByIds",
  ChangesGetAll: "ChangesGetAll",

  // Мероприятия
  MeetUpSave: "MeetUpSave",
  MeetUpGetAll: "MeetUpGetAll",
  MeetUpGetById: "MeetUpGetById",
  MeetUpDelete: "MeetUpDelete",
  MeetUpCreated: "MeetUpCreated",
  MeetUpUpdated: "MeetUpUpdated",
  MeetUpDeleted: "MeetUpDeleted",
  MeetupsForDepartmentGetAll: "MeetupsForDepartmentGetAll",

  // Сессии мероприятий
  ArrangementSave: "ArrangementSave",
  ArrangementsGetAll: "ArrangementsGetAll",
  ArrangementById: "ArrangementById",
  ArrangementDelete: "ArrangementDelete",
  ArrangementCreated: "ArrangementCreated",
  ArrangementUpdated: "ArrangementUpdated",
  ArrangementDeleted: "ArrangementDeleted",
  ArrangementMakePruffmeSession: "ArrangementMakePruffmeSession",
  ArrangementMakePruffmeReport: "ArrangementMakePruffmeReport",

  // Тренеры мероприятий
  MeetUpTrainerSave: "MeetUpTrainerSave",
  MeetUpTrainerGetAll: "MeetUpTrainerGetAll",
  MeetUpTrainerById: "MeetUpTrainerById",
  MeetUpTrainerDelete: "MeetUpTrainerDelete",
  MeetUpTrainerCreated: "MeetUpTrainerCreated",
  MeetUpTrainerUpdated: "MeetUpTrainerUpdated",
  MeetUpTrainerDeleted: "MeetUpTrainerDeleted",

  // Студенты мероприятий
  MeetUpStudentSave: "MeetUpStudentSave",
  MeetUpStudentSaveBulk: "MeetUpStudentSaveBulk",
  MeetUpStudentGetAll: "MeetUpStudentGetAll",
  MeetUpStudentById: "MeetUpStudentById",
  MeetUpStudentDelete: "MeetUpStudentDelete",
  MeetUpStudentDeleteBulk: "MeetUpStudentDeleteBulk",
  MeetUpStudentCreated: "MeetUpStudentCreated",
  MeetUpStudentUpdated: "MeetUpStudentUpdated",
  MeetUpStudentDeleted: "MeetUpStudentDeleted",

  // Заявки мероприятий
  MeetUpApplicationSave: "MeetUpApplicationSave",
  MeetUpApplicationSaveBulk: "MeetUpApplicationSaveBulk",
  MeetUpApplicationGetAll: "MeetUpApplicationGetAll",
  MeetUpApplicationById: "MeetUpApplicationById",
  MeetUpApplicationDelete: "MeetUpApplicationDelete",
  MeetUpApplicationCreated: "MeetUpApplicationCreated",
  MeetUpApplicationUpdated: "MeetUpApplicationUpdated",
  MeetUpApplicationDeleted: "MeetUpApplicationDeleted",

  GetPathnameStatuses: "GetPathnameStatuses",
  CleanPathnameStatuses: "CleanPathnameStatuses",
};

export const LiveEventsMapping = {
  [DependentEntities.applications]: {
    Created: Event.MeetUpApplicationCreated.toString(),
    Updated: Event.MeetUpApplicationUpdated.toString(),
    Deleted: Event.MeetUpApplicationDeleted.toString(),
  },
  [DependentEntities.students]: {
    Created: Event.MeetUpStudentCreated.toString(),
    Updated: Event.MeetUpStudentUpdated.toString(),
    Deleted: Event.MeetUpStudentDeleted.toString(),
  },
  [DependentEntities.trainers]: {
    Created: Event.MeetUpTrainerCreated.toString(),
    Updated: Event.MeetUpTrainerUpdated.toString(),
    Deleted: Event.MeetUpTrainerDeleted.toString(),
  },
  [DependentEntities.arrangements]: {
    Created: Event.ArrangementCreated.toString(),
    Updated: Event.ArrangementUpdated.toString(),
    Deleted: Event.ArrangementDeleted.toString(),
  }
}

export const mutationEvents = Object.values(Event).reduce((acc, eventType) => {
  if (
    eventType.indexOf("Get") !== -1 ||
    eventType.indexOf("Data") !== -1 ||
    eventType.indexOf("Stats") !== -1 ||
    eventType.indexOf("Retrieve") !== -1 ||
    eventType.indexOf("UserCourseElementSetCmi") !== -1 ||
    eventType.indexOf("DepartmentsCheckExist") !== -1 ||
    eventType.indexOf("DepartmentsWithUsers") !== -1 ||
    eventType.indexOf("UserCourseElementSetSuspend") !== -1 ||
    eventType.indexOf("UserCourseElementStart") !== -1
  ) {
    return acc;
  }

  acc.push(eventType);

  return acc;
}, []);
