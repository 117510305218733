import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

export function FormBlock(props) {
  const { children, className, expanded, submit, hidden } = props;

  const formBlockClassName = cn("form_bl", {
    form_bl_expanded: expanded,
    form_bl_submit: submit,
  }, className)

  return (
    <div className={formBlockClassName} hidden={hidden}>
      {children}
    </div>
  );
}

FormBlock.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  expanded: PropTypes.bool,
  submit: PropTypes.bool,
};

FormBlock.defaultProps = {
  className: '',
  expanded: false,
  submit: false,
};
